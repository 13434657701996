_cruxUtils._cruxLocale = {
"crux.no.bc.message":"\u041d\u0435\u0442 \u043e\u0431\u0449\u0438\u0445 \u0441\u0432\u0435\u0434\u0435\u043d\u0438\u0439. \u041d\u0430\u0436\u043c\u0438\u0442\u0435 \u043d\u0430 \u0437\u0430\u043f\u0438\u0441\u044c, \u0447\u0442\u043e\u0431\u044b \u043f\u0440\u043e\u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u043f\u043e\u0434\u0440\u043e\u0431\u043d\u044b\u0435 \u0441\u0432\u0435\u0434\u0435\u043d\u0438\u044f",
"crm.appointments.status.markcompleted1": "\u041f\u043e\u043c\u0435\u0442\u0438\u0442\u044c \u043a\u0430\u043a \u0437\u0430\u0432\u0435\u0440\u0448.",
"crm.mass.actions.all.selected": "\u0412 \u044d\u0442\u043e\u043c \u043f\u0440\u0435\u0434\u0441\u0442\u0430\u0432\u043b\u0435\u043d\u0438\u0438 \u0432\u044b\u0431\u0440\u0430\u043d\u044b <span class=\"dIB vaM\">\u0432\u0441\u0435</span><span class='mL3 mR3 crm-font-bold dIB vaM recordCountHt'> {0}</span><span class=\"dIB vaM\"> {1}.</span>",
"crm.module.selectall": "\u0412\u044b\u0431\u0440\u0430\u0442\u044c \u0432\u0441\u0435 {0} \u0432 \u044d\u0442\u043e\u043c \u043f\u0440\u0435\u0434\u0441\u0442\u0430\u0432\u043b\u0435\u043d\u0438\u0438",
"records": "\u0437\u0430\u043f.",
"crm.record.not.accessible": "\u0417\u0430\u043f\u0438\u0441\u0438 \u043d\u0435\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u044b",
"crm.view.calls.recording.err.msg": "\u0412 \u0433\u043e\u043b\u043e\u0441\u043e\u0432\u043e\u0439 \u0437\u0430\u043f\u0438\u0441\u0438 \u043d\u0435\u0442 URL-\u0430\u0434\u0440\u0435\u0441\u0430 \u0432\u043e\u0441\u043f\u0440\u043e\u0438\u0437\u0432\u043e\u0434\u0438\u043c\u043e\u0433\u043e \u0430\u0443\u0434\u0438\u043e.",
"crm.listview.selected.records": "<span class='mL3 crm-small-font-size crm-font-bold vaM dIB'>{0} </span><span class=\"mL3 seletcedRecords\">\u00a0\u0412\u044b\u0431\u0440\u0430\u043d\u043e: {1}.</span>",
"totalrecords": "\u0412\u0441\u0435 \u0437\u0430\u043f\u0438\u0441\u0438",
"crm.listview.options.text.clip": "\u041e\u0431\u0440\u0435\u0437\u0430\u0442\u044c \u0442\u0435\u043a\u0441\u0442",
"crm.listview.options.text.wrap": "\u041f\u0435\u0440\u0435\u043d\u043e\u0441 \u043f\u043e \u0441\u043b\u043e\u0432\u0430\u043c",
"crm.listview.options.reset.width": "\u0421\u0431\u0440\u043e\u0441\u0438\u0442\u044c \u0440\u0430\u0437\u043c\u0435\u0440 \u0441\u0442\u043e\u043b\u0431\u0446\u0430",
"crm.listview.options.manage": "\u0423\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u0442\u043e\u043b\u0431\u0446\u0430\u043c\u0438",
"crm.listview.customview.locked": "\u041d\u0435\u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e \u043d\u0430\u0441\u0442\u0440\u043e\u0438\u0442\u044c \u0441\u0442\u043e\u043b\u0431\u0446\u044b, \u0442. \u043a. \u044d\u0442\u043e \u043f\u0440\u0435\u0434\u0441\u0442\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d\u043e",
"crm.api.apidashboard.SortBy": "\u0421\u043e\u0440\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043f\u043e",
"crm.privacy.listview.consent.locked": "\u041e\u0431\u0440\u0430\u0431\u043e\u0442\u043a\u0430 \u044d\u0442\u043e\u0439 \u0437\u0430\u043f\u0438\u0441\u0438 \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d\u0430",
"crm.label.reschedule.call": "\u0418\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 {0}",
"crm.button.edit": "\u0418\u0437\u043c\u0435\u043d\u0438\u0442\u044c",
"Delete": "\u0423\u0434\u0430\u043b\u0438\u0442\u044c",
"crm.appointments.status.markcancel": "\u041e\u0442\u043c\u0435\u043d\u0438\u0442\u044c {0}",
"crm.appointments.status.markreschedule": "\u0418\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 {0}",
"crm.label.cancel.call": "\u041e\u0442\u043c\u0435\u043d\u0438\u0442\u044c {0}",
"crm.label.mark.completed": "\u041f\u043e\u043c\u0435\u0442\u0438\u0442\u044c \u043a\u0430\u043a \u0437\u0430\u0432\u0435\u0440\u0448.",
"crm.listview.customview.locked": "\u041d\u0435\u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e \u043d\u0430\u0441\u0442\u0440\u043e\u0438\u0442\u044c \u0441\u0442\u043e\u043b\u0431\u0446\u044b, \u0442. \u043a. \u044d\u0442\u043e \u043f\u0440\u0435\u0434\u0441\u0442\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d\u043e",
"pf.deleted.state": "\u0423\u0434\u0430\u043b\u0438\u0442\u044c \u0441\u043e\u0441\u0442\u043e\u044f\u043d\u0438\u0435",
"crm.project.integration.title": "Zoho Projects",
"crm.integ.sync.message": "\u0421\u0438\u043d\u0445\u0440\u043e\u043d\u0438\u0437\u0430\u0446\u0438\u044f \u0441 {0}",
"crm.schedules.prefix.in": "\u0412",
"crm.record.record.locked.other": "\u0417\u0430\u043f\u0438\u0441\u044c \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d\u0430 \u0434\u043b\u044f \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0435\u0439 \u0441 \u0434\u0440\u0443\u0433\u0438\u043c \u043f\u0440\u043e\u0444\u0438\u043b\u0435\u043c.",
"crm.record.lock.record.locked": "\u0417\u0430\u043f\u0438\u0441\u044c \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d\u0430.",
"crm.approvalProcess.label.waitingForApproval": "\u041e\u0436\u0438\u0434\u0430\u0435\u0442 \u0443\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043d\u0438\u044f",
"crm.reviewprocess.record.review.pending": "\u041e\u0436\u0438\u0434\u0430\u0435\u0442 \u0440\u0430\u0441\u0441\u043c\u043e\u0442\u0440\u0435\u043d\u0438\u044f",
"crm.approvalProcess.label.waitingForFindAndMerge": "\u0418\u0434\u0435\u0442 \u043f\u0440\u043e\u0446\u0435\u0441\u0441 \u043e\u0431\u044a\u0435\u0434\u0438\u043d\u0435\u043d\u0438\u044f \u0434\u0443\u0431\u043b\u0438\u043a\u0430\u0442\u043e\u0432.",
"crm.privacy.listview.consent.locked": "\u041e\u0431\u0440\u0430\u0431\u043e\u0442\u043a\u0430 \u044d\u0442\u043e\u0439 \u0437\u0430\u043f\u0438\u0441\u0438 \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d\u0430",
"crm.zia.vision.processing": "Zia \u0432\u044b\u043f\u043e\u043b\u043d\u044f\u0435\u0442 \u043f\u0440\u043e\u0432\u0435\u0440\u043a\u0443 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0439.",
"crm.zia.vision.rejected.msg": "\u0417\u0430\u043f\u0438\u0441\u044c \u043e\u0442\u043a\u043b\u043e\u043d\u0435\u043d\u0430. \u041f\u0440\u0438\u0447\u0438\u043d\u0430: \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043d\u0435 \u043f\u0440\u043e\u0448\u043b\u043e \u043f\u0440\u043e\u0432\u0435\u0440\u043a\u0443.",
"crm.zia.vision.record.failure.msg": "\u0417\u0430\u043f\u0438\u0441\u044c \u043e\u0436\u0438\u0434\u0430\u0435\u0442 \u0443\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043d\u0438\u044f. \u041f\u0440\u0438\u0447\u0438\u043d\u0430: \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043d\u0435 \u043f\u0440\u043e\u0448\u043b\u043e \u043f\u0440\u043e\u0432\u0435\u0440\u043a\u0443.",
"crm.alert.max.cvcols.reached": "\u041c\u043e\u0436\u043d\u043e \u0432\u044b\u0431\u0440\u0430\u0442\u044c \u043e\u043f\u0440\u0435\u0434\u0435\u043b\u0435\u043d\u043d\u043e\u0435 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0441\u0442\u043e\u043b\u0431\u0446\u043e\u0432 (\u043d\u0435 \u0431\u043e\u043b\u0435\u0435 {0}).",
"crm.label.small.tags": "\u0422\u0435\u0433\u0438",
"crm.label.edit.module": "\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c {0}",
"crm.social.integ.no.result": "\u0420\u0435\u0437\u0443\u043b\u044c\u0442\u0430\u0442\u044b \u043d\u0435 \u043d\u0430\u0439\u0434\u0435\u043d\u044b",
"custommodule.crmfield.fl.name": "{0} - \u0438\u043c\u044f",
"Appointment": "\u0412\u0441\u0442\u0440\u0435\u0447\u0430",
"crm.button.create.call": "\u0421\u043e\u0437\u0434\u0430\u0442\u044c \u0432\u044b\u0437\u043e\u0432",
"crm.module.create": "{0} - \u0441\u043e\u0437\u0434\u0430\u0442\u044c",
"crm.customview.activty.badge": "\u042d\u043c\u0431\u043b\u0435\u043c\u0430 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044f",
"crm.button.callnow": "\u041f\u043e\u0437\u0432\u043e\u043d\u0438\u0442\u044c",
"crm.manualcalllist.complete.call": "\u0417\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0432\u044b\u0437\u043e\u0432",
"crm.label.schedule.call": "\u0417\u0430\u043f\u043b\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0432\u044b\u0437\u043e\u0432",
"crm.filter.label.replied":"\u0441 \u043e\u0442\u0432\u0435\u0442\u043e\u043c",
"bytes": "\u0431\u0430\u0439\u0442\u043e\u0432",
"crm.field.label.user.lookup": "\u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c",
"crm.translation.refresh.cases": "\u041d\u0435 \u0443\u0434\u0430\u043b\u043e\u0441\u044c \u043d\u0430\u0439\u0442\u0438 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435, \u043a\u043e\u0442\u043e\u0440\u043e\u0435 \u0432\u044b \u043f\u044b\u0442\u0430\u0435\u0442\u0435\u0441\u044c \u0432\u044b\u043f\u043e\u043b\u043d\u0438\u0442\u044c. \u041e\u0431\u043d\u043e\u0432\u0438\u0442\u0435 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0443 \u0438 \u043f\u043e\u0432\u0442\u043e\u0440\u0438\u0442\u0435 \u043f\u043e\u043f\u044b\u0442\u043a\u0443.",
"crm.project.sync.notes.info": "\u0411\u0443\u0434\u0443\u0442 \u043f\u043e\u043a\u0430\u0437\u0430\u043d\u044b \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0438 \u043a \u0437\u0430\u0434\u0430\u0447\u0430\u043c \u0442\u043e\u043b\u044c\u043a\u043e \u0434\u043b\u044f \u0441\u0432\u044f\u0437\u0430\u043d\u043d\u044b\u0445 \u043f\u0440\u043e\u0435\u043a\u0442\u043e\u0432",
"crux.note.content.limit.warning.msg":"\u0412 \u0441\u043e\u0434\u0435\u0440\u0436\u0438\u043c\u043e\u043c \u0437\u0430\u043c\u0435\u0442\u043e\u043a \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u043e \u0441\u043e\u0445\u0440\u0430\u043d\u044f\u0442\u044c \u043d\u0435 \u0431\u043e\u043b\u0435\u0435 {0} \u0441\u0438\u043c\u0432.",
"crux.note.formatting.options":"\u041f\u0430\u0440\u0430\u043c\u0435\u0442\u0440\u044b \u0444\u043e\u0440\u043c\u0430\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u044f",
"crm.label.required": "\u041e\u0431\u044f\u0437\u0430\u0442\u0435\u043b\u044c\u043d\u043e",
"crux.condition.not.belongs.to":"\u043d\u0435 \u043f\u0440\u0438\u043d\u0430\u0434\u043b\u0435\u0436\u0438\u0442 {0}",
"crm.project.sync.notes.info": "\u0411\u0443\u0434\u0443\u0442 \u043f\u043e\u043a\u0430\u0437\u0430\u043d\u044b \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0438 \u043a \u0437\u0430\u0434\u0430\u0447\u0430\u043c \u0442\u043e\u043b\u044c\u043a\u043e \u0434\u043b\u044f \u0441\u0432\u044f\u0437\u0430\u043d\u043d\u044b\u0445 \u043f\u0440\u043e\u0435\u043a\u0442\u043e\u0432",
"crm.sf.permission.no.edit": "\u0423 \u0432\u0430\u0441 \u043d\u0435\u0442 \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u0438\u044f \u043d\u0430 \u043e\u0431\u043d\u043e\u0432\u043b\u0435\u043d\u0438\u0435 \u0437\u0430\u043f\u0438\u0441\u0435\u0439.",
"crm.sf.permission.no.create": "\u0423 \u0432\u0430\u0441 \u043d\u0435\u0442 \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u0438\u044f \u043d\u0430 \u0434\u043e\u0431\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0437\u0430\u043f\u0438\u0441\u0435\u0439.",
"crm.sf.permission.no.delete": "\u0423 \u0432\u0430\u0441 \u043d\u0435\u0442 \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u0438\u044f \u043d\u0430 \u0443\u0434\u0430\u043b\u0435\u043d\u0438\u0435 \u0437\u0430\u043f\u0438\u0441\u0435\u0439.",
"crux.note.this.note":"this note",
"KB": "\u041a\u0411",
"MB": "\u041c\u0411",
"current.logged.in.user.role": "\u0420\u043e\u043b\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f, \u0432\u043e\u0448\u0435\u0434\u0448\u0435\u0433\u043e \u0432 \u0441\u0438\u0441\u0442\u0435\u043c\u0443",
"Unit Price": "\u0426\u0435\u043d\u0430 \u0437\u0430 \u0435\u0434.",
"Qty in Stock": "\u041a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0432 \u043d\u0430\u043b\u0438\u0447\u0438\u0438",
"crm.label.Product Code": "\u041a\u043e\u0434 \u043f\u0440\u043e\u0434\u0443\u043a\u0442\u0430",
"TotalAfterDiscount": "\u0421\u0443\u043c\u043c\u0430 \u043f\u043e\u0441\u043b\u0435 \u0441\u043a\u0438\u0434\u043a\u0438",
"crm.lookupfilter.entity.errormsg": "\u0412\u044b\u0431\u0440\u0430\u043d\u043d\u0430\u044f {0} \u043d\u0435 \u0441\u043e\u043e\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0443\u0435\u0442 \u043a\u0440\u0438\u0442\u0435\u0440\u0438\u044f\u043c \u043f\u043e\u0434\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0438.",
"crm.iamexception.maxlen": "\u0412 \u043f\u043e\u043b\u0435 {1} \u043c\u043e\u0436\u043d\u043e \u0443\u043a\u0430\u0437\u0430\u0442\u044c \u043e\u043f\u0440\u0435\u0434\u0435\u043b\u0435\u043d\u043d\u043e\u0435 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0441\u0438\u043c\u0432\u043e\u043b\u043e\u0432 (\u043d\u0435 \u0431\u043e\u043b\u0435\u0435 {0})",
"crm.record.locking.permission.denied": "\u041d\u0435\u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e \u0432\u044b\u043f\u043e\u043b\u043d\u0438\u0442\u044c \u044d\u0442\u043e \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435, \u043f\u043e\u0441\u043a\u043e\u043b\u044c\u043a\u0443 \u0437\u0430\u043f\u0438\u0441\u044c \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d\u0430.",
"List Price": "\u0426\u0435\u043d\u0430 \u043f\u043e \u043f\u0440\u0430\u0439\u0441-\u043b\u0438\u0441\u0442\u0443",
"crm.label.no.more.records": "\u0411\u043e\u043b\u044c\u0448\u0435 \u043d\u0435\u0442 \u0437\u0430\u043f\u0438\u0441\u0435\u0439",
"crm.workflow.rule.created.dateAndTime":"{0} {1} \u0432 {2}",
"crux.new.note.msg":"\u041e \u0447\u0435\u043c \u044d\u0442\u0430 \u0437\u0430\u043c\u0435\u0442\u043a\u0430?",
"crm.image.uploaded":"\u0418\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043e\u0442\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043e",
"crm.imageupload.drag.drop.here":"\u041f\u0435\u0440\u0435\u0442\u0430\u0449\u0438\u0442\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u044f \u0441\u044e\u0434\u0430",
"crm.fileupload.drag.drop.here":"\u041f\u0435\u0440\u0435\u0442\u0430\u0449\u0438\u0442\u0435 \u0444\u0430\u0439\u043b\u044b \u0441\u044e\u0434\u0430",
"crm.fileupload.wait.msg":"\u0414\u043e\u0436\u0434\u0438\u0442\u0435\u0441\u044c \u0437\u0430\u0432\u0435\u0440\u0448\u0435\u043d\u0438\u044f \u043e\u0442\u043f\u0440\u0430\u0432\u043a\u0438 \u0432\u044b\u0431\u0440\u0430\u043d\u043d\u044b\u0445 \u0444\u0430\u0439\u043b\u043e\u0432",
"crm.fileupload.attach.fail":"\u041d\u0435 \u0443\u0434\u0430\u043b\u043e\u0441\u044c \u0432\u043b\u043e\u0436\u0438\u0442\u044c",
"crm.fileupload.support.format":"\u0424\u043e\u0440\u043c\u0430\u0442 \u0444\u0430\u0439\u043b\u0430 \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u043a\u0438 (JPEG, JPG, PNG, PDF) \u0434\u043b\u044f \u043e\u0431\u0443\u0447\u0435\u043d\u0438\u044f Zia",
"custmr.prtl.user.role": "\u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u043f\u043e\u0440\u0442\u0430\u043b\u0430",
"crm.subform.empty.alert.header":"\u041d\u0435\u043b\u044c\u0437\u044f \u0441\u043e\u0445\u0440\u0430\u043d\u0438\u0442\u044c \u043f\u0443\u0441\u0442\u0443\u044e \u0441\u0442\u0440\u043e\u043a\u0443",
"crm.crud.lookup.module.inaccessible": "\u0423 \u0432\u0430\u0441 \u043d\u0435\u0442 \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u0438\u044f \u043d\u0430 \u0434\u043e\u0441\u0442\u0443\u043f \u043a \u044d\u0442\u043e\u043c\u0443 \u043c\u043e\u0434\u0443\u043b\u044e \u043f\u043e\u0434\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0438",
"crm.crud.lookup.inaccessible.record": "\u0417\u0430\u043f\u0438\u0441\u044c \u043d\u0435 \u0441\u0443\u0449\u0435\u0441\u0442\u0432\u0443\u0435\u0442 \u0438\u043b\u0438 \u043d\u0435\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u0430 \u0434\u043b\u044f \u0432\u0430\u0441",
"crm.custombutton.valid.weburl.check": "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0437\u043d\u0430\u0447\u0435\u043d\u0438\u0435.",
"crm.label.subform.goto.top": "\u041d\u0430\u0432\u0435\u0440\u0445 \u0441\u043f\u0438\u0441\u043a\u0430",
"crm.label.subform.row.show.all": "\u041f\u043e\u043a\u0430\u0437\u0430\u0442\u044c \u0432\u0441\u0435 ({0})",
"cpq.pr.nodiscount": "\u041d\u0435\u0442 \u0441\u0432\u044f\u0437\u0430\u043d\u043d\u044b\u0445 \u0441\u043a\u0438\u0434\u043e\u043a",
"crm.inventory.lineitem.no.pricebook.new": "\u041d\u0435\u0442 \u0441\u0432\u044f\u0437\u0430\u043d\u043d\u044b\u0445 {0}.",
"crm.label.subform.allowedlimit": "\u0414\u043e\u0441\u0442\u0438\u0433\u043d\u0443\u0442\u043e \u043f\u0440\u0435\u0434\u0435\u043b\u044c\u043d\u043e\u0435 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0441\u0442\u0440\u043e\u043a ({0})",
"crm.reports.integ.okay": "\u041e\u041a",
"crm.crud.subform.deleted.record": "\u0421\u0442\u0440\u043e\u043a\u0438 \u043e\u0431\u043d\u043e\u0432\u043b\u0435\u043d\u043d\u043e\u0439 \u0432\u043b\u043e\u0436\u0435\u043d\u043d\u043e\u0439 \u0444\u043e\u0440\u043c\u044b \u0431\u044b\u043b\u0438 \u0443\u0436\u0435 \u0443\u0434\u0430\u043b\u0435\u043d\u044b. \u041e\u0431\u043d\u043e\u0432\u0438\u0442\u0435 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0443 \u0438 \u043f\u043e\u0432\u0442\u043e\u0440\u0438\u0442\u0435 \u043f\u043e\u043f\u044b\u0442\u043a\u0443.",
"crux.criteria.fieldlabel.valid.check":"\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0443\u044e \u043c\u0435\u0442\u043a\u0443 \u043f\u043e\u043b\u044f",
"crm.subform.delete.confirm.header":"Удалить строку подчиненной формы",
"crm.subform.delete.confirm.msg": "Действительно удалить выбранную строку в {0}?",
"crm.subform.delete.tooltip.msg" :"Не удалось удалить строку. Для {0} необходимо указать запись хотя бы с одной строкой.",
	"crm.condition.belongs.to":"\u043f\u0440\u0438\u043d\u0430\u0434\u043b\u0435\u0436\u0438\u0442 {0}",//no i18n
"crm.condition.not.belongs.to":"\u043d\u0435 \u043f\u0440\u0438\u043d\u0430\u0434\u043b\u0435\u0436\u0438\u0442 {0}",//no i18n
"crm.orchestration.error.selectvalue": "\u0412\u044b\u0431\u0435\u0440\u0438\u0442\u0435 \u0437\u043d\u0430\u0447\u0435\u043d\u0438\u0435",
	"crm.attach.upload.image":"\u0412\u043b\u043e\u0436\u0438\u0442\u044c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u044f",//no i18n
"crm.general.select.photo":"\u0412\u044b\u0431\u0435\u0440\u0438\u0442\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435",//no i18n
"crm.imageupload.wait.msg":"\u0414\u043e\u0436\u0434\u0438\u0442\u0435\u0441\u044c \u043e\u0442\u043f\u0440\u0430\u0432\u043a\u0438 \u0432\u044b\u0431\u0440\u0430\u043d\u043d\u044b\u0445 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0439.",//no i18n
"crm.image.filepicker.desc.draganddrop":"\u041f\u0435\u0440\u0435\u0442\u0430\u0449\u0438\u0442\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u044f.",//no i18n
"crm.image.filepicker.desc.browse":"\u041d\u0430\u0436\u043c\u0438\u0442\u0435 \u0434\u043b\u044f \u0432\u044b\u0431\u043e\u0440\u0430 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0439...",//no i18n
"crm.attach.upload.userinfo":"\u041e\u0431\u0449\u0438\u0439 \u0440\u0430\u0437\u043c\u0435\u0440 \u043d\u0435 \u0434\u043e\u043b\u0436\u0435\u043d \u043f\u0440\u0435\u0432\u044b\u0448\u0430\u0442\u044c <span class",//no i18n
"crm.image.supported.formats":"\u041f\u043e\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0435\u043c\u044b\u0435 \u0444\u043e\u0440\u043c\u0430\u0442\u044b: JPEG, PNG, GIF \u0438 BMP.",//no i18n
"crm.imageupload.allowed.field.length":"\u041c\u043e\u0436\u043d\u043e \u043e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u043d\u0435 \u0431\u043e\u043b\u0435\u0435 {0} \u0438\u0437\u043e\u0431\u0440\u0430\u0436.",//no i18n
"crm.general.crop.and.set":"\u041e\u0431\u0440\u0435\u0437\u0430\u0442\u044c \u0438 \u0432\u044b\u0431\u0440\u0430\u0442\u044c",//no i18n
"crm.attach.option.label":"\u0412\u043b\u043e\u0436\u0438\u0442\u044c",//no i18n
"crm.image.crop.and.rotate":"\u041e\u0431\u0440\u0435\u0437\u0430\u0442\u044c \u0438 \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c",//no i18n
"crm.image.resolution":"\u0420\u0435\u0448\u0435\u043d\u0438\u0435",//no i18n
"crm.attachment.size":"\u0420\u0430\u0437\u043c\u0435\u0440",//no i18n
"crm.label.close":"\u0417\u0430\u043a\u0440\u044b\u0442\u044c",//no i18n
"crm.label.previous":"\u041f\u0440\u0435\u0434\u044b\u0434\u0443\u0449\u0438\u0439",//no i18n
"crm.label.next":"\u0414\u0430\u043b\u0435\u0435",//no i18n
"crm.subform.record.create.maxrow.limit": "\u0412 {1} \u0434\u043e\u043f\u0443\u0441\u043a\u0430\u0435\u0442\u0441\u044f \u043e\u043f\u0440\u0435\u0434\u0435\u043b\u0435\u043d\u043d\u043e\u0435 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0437\u0430\u043f\u0438\u0441\u0435\u0439 (\u043d\u0435 \u0431\u043e\u043b\u0435\u0435\u00a0{0})",
"crm.label.subform.addrows": "\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u0441\u0442\u0440\u043e\u043a\u0443",
"crm.tax.association.check": "\u0421 \u044d\u0442\u0438\u043c \u043f\u0440\u043e\u0434\u0443\u043a\u0442\u043e\u043c \u043d\u0435 \u0441\u0432\u044f\u0437\u0430\u043d\u044b \u043d\u0430\u043b\u043e\u0433\u0438.",
	"crm.gallery.inventory.template.discount": "\u0421\u043a\u0438\u0434\u043a\u0430",
	"crm.inventory.discount.scheme.range": "\u0414\u0438\u0430\u043f\u0430\u0437\u043e\u043d",
	"Description": "\u041e\u043f\u0438\u0441\u0430\u043d\u0438\u0435",
	"crm.inventory.lineitem.no.pricebook": "\u041d\u0435\u0442 \u0441\u0432\u044f\u0437\u0430\u043d\u043d\u044b\u0445 \u043f\u0440\u0435\u0439\u0441\u043a\u0443\u0440\u0430\u043d\u0442\u043e\u0432.",
	"crm.recordImage.previewimage":"\u041f\u0440\u0435\u0434\u0432\u0430\u0440\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u043f\u0440\u043e\u0441\u043c\u043e\u0442\u0440",//no i18n
"crm.FileuploadField.addNewImage":"\u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435",//no i18n
"crm.fileuploader.removefile":"\u0423\u0434\u0430\u043b\u0438\u0442\u044c",//no i18n
	"voc.gc.configure":"\u041d\u0430\u0441\u0442\u0440\u043e\u0438\u0442\u044c",//no i18n
"Edit":"\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c",//no i18n
	"crm.label.field":"\u041f\u043e\u043b\u0435",//no i18n
"crm.label.value":"\u0417\u043d\u0430\u0447\u0435\u043d\u0438\u0435",//no i18n
	"crm.button.clone":"\u041a\u043b\u043e\u043d\u0438\u0440\u043e\u0432\u0430\u0442\u044c",//no i18n
	"sentiment.criteria.wrongcriteria":"\u0417\u043d\u0430\u0447\u0435\u043d\u0438\u0435 \u043a\u0440\u0438\u0442\u0435\u0440\u0438\u044f \u043d\u0435 \u0434\u043e\u043b\u0436\u043d\u043e \u043f\u0440\u0435\u0432\u044b\u0448\u0430\u0442\u044c {0}",//no i18n
"crm.mb.field.common.splc":"\u0418\u0441\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u044c \u0441\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u0441\u0438\u043c\u0432\u043e\u043b\u044b \u0437\u0430\u043f\u0440\u0435\u0449\u0435\u043d\u043e. \u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u043e\u0435 \u0437\u043d\u0430\u0447\u0435\u043d\u0438\u0435.",//no i18n
	"crm.view.attachment.download":"\u0417\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c",//no i18n
"crm.label.view":"\u041f\u0440\u043e\u0441\u043c\u043e\u0442\u0440",//no i18n
	"crm.label.field.plural":"\u043f\u043e\u043b\u044f",//no i18n
	"crm.label.in.minutes":"{0} (\u0432 \u043c\u0438\u043d\u0443\u0442\u0430\u0445)",//no i18n
	"crm.security.roles.list":"\u0421\u043f\u0438\u0441\u043e\u043a \u0440\u043e\u043b\u0435\u0439",//no i18n
"crm.security.roles.lookup.info":"\u0412\u044b\u0431\u0435\u0440\u0438\u0442\u0435 \u043e\u0434\u043d\u0443 \u0440\u043e\u043b\u044c \u0438\u0437 \u0441\u043f\u0438\u0441\u043a\u0430.",//no i18n
"crm.territory.addterritory":"\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u0442\u0435\u0440\u0440\u0438\u0442\u043e\u0440\u0438\u044e",//no i18n
"crm.title.edit.territory":"\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0442\u0435\u0440\u0440\u0438\u0442\u043e\u0440\u0438\u044e",//no i18n
"crm.territory.title.assign.territories":"\u041d\u0430\u0437\u043d\u0430\u0447\u0438\u0442\u044c \u0442\u0435\u0440\u0440\u0438\u0442\u043e\u0440\u0438\u0438",//no i18n
	"crm.label.context.help":"\u0421\u043f\u0440\u0430\u0432\u043a\u0430",//no i18n
	"crm.label.from":"\u0421",//no i18n
"crm.label.to":"\u041f\u043e",//no i18n
	"workflow.option.webhookFailure.fromDate":"\u0421",//no i18n
"workflow.option.webhookFailure.toDate":"\u041f\u043e",//no i18n
"crm.custom.field.less.than.equalto":"\u0414\u043b\u0438\u043d\u0430 {0} \u0434\u043e\u043b\u0436\u043d\u0430 \u0431\u044b\u0442\u044c \u043c\u0435\u043d\u044c\u0448\u0435 \u0438\u043b\u0438 \u0440\u0430\u0432\u043d\u0430 {1}.",//no i18n
	"crm.template.listview.search.no.results":"\u0420\u0435\u0437\u0443\u043b\u044c\u0442\u0430\u0442\u044b \u043d\u0435 \u043d\u0430\u0439\u0434\u0435\u043d\u044b",//No I18n
	"crm.label.tag.new":"\u041d\u043e\u0432\u044b\u0439 \u0442\u0435\u0433",//No I18n
	"crm.wf.usage.date.criteria.error.msg":"\u041d\u0430\u0447\u0430\u043b\u044c\u043d\u0430\u044f \u0434\u0430\u0442\u0430 \u0434\u043e\u043b\u0436\u043d\u0430 \u043f\u0440\u0435\u0434\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u043a\u043e\u043d\u0435\u0447\u043d\u043e\u0439 \u0434\u0430\u0442\u0435.",//no i18n
	"crm.label.enter.tag":"\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0442\u0435\u0433\u0438",//No I18n
	"crux.comboBox.max.limit":"\u041c\u043e\u0436\u043d\u043e \u0432\u044b\u0431\u0440\u0430\u0442\u044c \u043d\u0435 \u0431\u043e\u043b\u0435\u0435\u00a0{0} {1}.",//NO I18n
	"Administrator":"\u0410\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440",//No I18n
	"Standard":"\u0421\u0442\u0430\u043d\u0434\u0430\u0440\u0442",//No I18n
	"crm.button.add":"\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c",//NO I18n
	"crm.label.users":"\u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0438", //NO I18n
  "crm.workflow.alert.roles":"\u0420\u043e\u043b\u0438", //NO I18n
  "crm.security.groups":"\u0413\u0440\u0443\u043f\u043f\u044b", //NO I18n
	"crm.label.available" : "\u0414\u043e\u0441\u0442\u0443\u043f\u043d\u043e", //NO I18n
	"crm.label.assign.manually" : "\u041d\u0430\u0437\u043d\u0430\u0447\u0438\u0442\u044c", //NO I18n
	"crm.globalsearch.option.all": "\u0412\u0441\u0435", //NO I18n
	"webform.status.Active":"\u0410\u043a\u0442\u0438\u0432\u043d\u043e", //NO I18n
	"Inactive":"\u041d\u0435\u0430\u043a\u0442\u0438\u0432\u043d.", //NO I18n
  "Confirmed":"\u041f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043d\u043e", //NO I18n
  "crm.user.component.unconfirmed":"\u041d\u0435\u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043d\u043d\u044b\u0435",//no i18n
  "DeletedUser":"\u0423\u0434\u0430\u043b\u0435\u043d\u043e", //NO I18n
  "crm.feed.group.admin":"\u0410\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440", //NO I18n
  "crm.ln.lable.current":"\u0422\u0435\u043a\u0443\u0449\u0438\u0439", //NO I18n
	"crm.label.selected": "\u0412\u044b\u0431\u0440\u0430\u043d\u043e",//NO I18n
	"crm.auditlog.user": "User", //NO I18n
	"cob.role": "\u0420\u043e\u043b\u044c", //NO I18n
	"zoho.email": "\u042d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u043f\u043e\u0447\u0442\u0430", //NO I18n
	"Profile": "\u041f\u0440\u043e\u0444\u0438\u043b\u044c", //NO I18n
	"crm.security.group.users.empty": "\u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0438 \u043d\u0435 \u043d\u0430\u0439\u0434\u0435\u043d\u044b.", //NO I18n
	"crm.label.picklist.none": "----", //NO I18n
	"crm.usrpop.non.selected" : "\u0412\u044b\u0431\u0440\u0430\u043d\u043d\u044b\u0435 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0438",//NO I18n
	"crm.zti.label.user": "\u0418\u043c\u044f \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f", //NO I18n
	"crm.label.notSelected" : "\u041d\u0435 \u0432\u044b\u0431\u0440\u0430\u043d\u043e",//NO I18n
	"AM" : "AM",//NO I18n
	"Call" : "\u0417\u0432\u043e\u043d\u043e\u043a",//NO I18n
	"crm.phoneNo.Link.Title" : "\u0412\u044b\u0437\u043e\u0432\u044b \u0441 \u043f\u043e\u043c\u043e\u0449\u044c\u044e Skype",//NO I18n
	"crm.button.cancel" : "\u041e\u0442\u043c\u0435\u043d\u0430",//NO I18n
	"crm.button.save" : "\u0421\u043e\u0445\u0440\u0430\u043d\u0438\u0442\u044c",//NO I18n
	"crm.no.data.found" : "\u0414\u0430\u043d\u043d\u044b\u0435 \u043e\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u044e\u0442.",//NO I18n
	"crm.label.no.options.found" : "\u041f\u0430\u0440\u0430\u043c\u0435\u0442\u0440\u044b \u043d\u0435 \u043d\u0430\u0439\u0434\u0435\u043d\u044b.",//No I18n
	"crm.globalsearch.search.title" : "\u041f\u043e\u0438\u0441\u043a",//No I18n
	"None" : "----",//No I18n
	"crm.label.criteria.pattern" : "\u0421\u0445\u0435\u043c\u0430 \u043a\u0440\u0438\u0442\u0435\u0440\u0438\u0435\u0432",//No I18n
	"crm.label.edit.criteria.pattern" : "\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0448\u0430\u0431\u043b\u043e\u043d",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "\u0421\u043a\u043e\u0431\u043a\u0438 \u0432 \u0448\u0430\u0431\u043b\u043e\u043d\u0435 \u043d\u0435 \u0441\u043e\u0432\u043f\u0430\u0434\u0430\u044e\u0442.",//No I18n
	"criteria.error.alert.brackets.invalid" : "\u041d\u0435\u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u044b\u0435 \u0441\u043a\u043e\u0431\u043a\u0438 \u0432\u043e\u043a\u0440\u0443\u0433 \u043e\u043f\u0435\u0440\u0430\u0442\u043e\u0440\u043e\u0432 \u0443\u0441\u043b\u043e\u0432\u0438\u044f.",//No I18n
	"crm.criteria.number.notmatch.check" : "\u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u0448\u0430\u0431\u043b\u043e\u043d \u0432 {0}.",//No I18n
	"criteria.error.alert.other.params" : "\u041d\u0435\u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u043e\u0435 \u0441\u043e\u0434\u0435\u0440\u0436\u0438\u043c\u043e\u0435 \u0448\u0430\u0431\u043b\u043e\u043d\u0430.", //No I18n
	"crm.label.search.for.users": "\u041f\u043e\u0438\u0441\u043a \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0435\u0439", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "\u0428\u0430\u0431\u043b\u043e\u043d \u043a\u0440\u0438\u0442\u0435\u0440\u0438\u0435\u0432 \u043d\u0435 \u0441\u043e\u043e\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0443\u0435\u0442 \u0432\u044b\u0431\u0440\u0430\u043d\u043d\u044b\u043c \u0443\u0441\u043b\u043e\u0432\u0438\u044f\u043c.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "\u0428\u0430\u0431\u043b\u043e\u043d \u043a\u0440\u0438\u0442\u0435\u0440\u0438\u0435\u0432 \u043d\u0435 \u0441\u043e\u043e\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0443\u0435\u0442 \u0432\u044b\u0431\u0440\u0430\u043d\u043d\u044b\u043c \u0443\u0441\u043b\u043e\u0432\u0438\u044f\u043c.", //No I18n
	"and" : "\u0438", //No I18n
	"or" : "\u0438\u043b\u0438", //No I18n
	"crm.label.or" : "\u0418\u041b\u0418", //No I18n
	"crm.label.and" : "\u0418", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0443\u044e \u043c\u0435\u0442\u043a\u0443 \u043f\u043e\u043b\u044f \u0432 \u0441\u0442\u0440\u043e\u043a\u0435 {0}.", //No I18n
	"crm.criteria.condition.valid.check" : "\u0423\u043a\u0430\u0436\u0438\u0442\u0435 \u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u043e\u0435 \u0443\u0441\u043b\u043e\u0432\u0438\u0435 \u0434\u043b\u044f {0}.", //No I18n
	"crm.field.valid.check" : "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u043e\u0435 {0}.", //No I18n
	"crm.custom.field.less.than.to" : "<i>\u041d\u0430\u0447\u0430\u043b\u044c\u043d\u044b\u0439</i> \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d \u0434\u043e\u043b\u0436\u0435\u043d \u0431\u044b\u0442\u044c \u043c\u0435\u043d\u044c\u0448\u0435 <i>\u043a\u043e\u043d\u0435\u0447\u043d\u043e\u0433\u043e</i> \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d\u0430.", //No I18n
	"crm.alert.label.savepattern" : "\u0421\u043e\u0445\u0440\u0430\u043d\u0438\u0442\u0435 \u0448\u0430\u0431\u043b\u043e\u043d, \u043f\u0440\u0435\u0436\u0434\u0435 \u0447\u0435\u043c \u0438\u0437\u043c\u0435\u043d\u044f\u0442\u044c \u043a\u0440\u0438\u0442\u0435\u0440\u0438\u0438.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "\u041d\u0435\u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e \u0434\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u0434\u043e\u043f\u043e\u043b\u043d\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u0435 \u043a\u0440\u0438\u0442\u0435\u0440\u0438\u0438.",//No I18n
	"is" : "\u0441\u043e\u0432\u043f\u0430\u0434\u0430\u0435\u0442 \u0441",//No I18n
	"isn\'t" : "\u043d\u0435",//No I18n
	"contains" : "\u0441\u043e\u0434\u0435\u0440\u0436\u0438\u0442",//No I18n
	"doesn\'t contain" : "\u043d\u0435 \u0441\u043e\u0434\u0435\u0440\u0436\u0438\u0442",//No I18n
	"starts with" : "\u043d\u0430\u0447\u0438\u043d\u0430\u0435\u0442\u0441\u044f \u0441",//No I18n
	"ends with" : "\u043e\u043a\u0430\u043d\u0447\u0438\u0432\u0430\u0435\u0442\u0441\u044f \u043d\u0430",//No I18n
	"is empty" : "\u043f\u0443\u0441\u0442.",//No I18n
	"is not empty" : "\u043d\u0435 \u043f\u0443\u0441\u0442.",//No I18n
	"is before" : "\u043c\u0435\u043d\u044c\u0448\u0435",//No I18n
	"is after" : "\u0431\u043e\u043b\u044c\u0448\u0435",//No I18n
	"between" : "\u043c\u0435\u0436\u0434\u0443",//No I18n
	"not between" : "\u043d\u0435 \u043d\u0430\u0445\u043e\u0434\u0438\u0442\u0441\u044f \u043c\u0435\u0436\u0434\u0443",//No I18n
	"Today" : "\u0421\u0435\u0433\u043e\u0434\u043d\u044f",//No I18n
	"Tommorow" : "\u0417\u0430\u0432\u0442\u0440\u0430",//No I18n
	"Tommorow Onwards" : "\u041d\u0430\u0447\u0438\u043d\u0430\u044f \u0441 \u0437\u0430\u0432\u0442\u0440\u0430\u0448\u043d\u0435\u0433\u043e \u0434\u043d\u044f",//No I18n
	"Yesterday" : "\u0412\u0447\u0435\u0440\u0430",//No I18n
	"Till Yesterday" : "\u0414\u043e \u0432\u0447\u0435\u0440\u0430\u0448\u043d\u0435\u0433\u043e \u0434\u043d\u044f",//No I18n
	"Last Month" : "\u041f\u0440\u043e\u0448\u043b\u044b\u0439 \u043c\u0435\u0441\u044f\u0446",//No I18n
	"Current Month" : "\u0422\u0435\u043a\u0443\u0449\u0438\u0439 \u043c\u0435\u0441\u044f\u0446", //No I18n
	"Next Month" : "\u0421\u043b\u0435\u0434\u0443\u044e\u0449\u0438\u0439 \u043c\u0435\u0441\u044f\u0446", //No I18n
	"Last Week" : "\u041f\u0440\u043e\u0448\u043b\u0430\u044f \u043d\u0435\u0434\u0435\u043b\u044f", //No I18n
	"Current Week" : "\u0422\u0435\u043a\u0443\u0449\u0430\u044f \u043d\u0435\u0434\u0435\u043b\u044f", //No I18n
	"Next Week" : "\u0421\u043b\u0435\u0434\u0443\u044e\u0449\u0430\u044f \u043d\u0435\u0434\u0435\u043b\u044f", //No I18n
	"Age in Days" : "\u0412\u0440\u0435\u043c\u044f \u0441\u0443\u0449\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u043d\u0438\u044f (\u0434\u043d.)", //No I18n
	"Due in Days" : "\u0421\u0440\u043e\u043a (\u0434\u043d.)", //No I18n
	"Scheduled" : "\u0417\u0430\u043f\u043b\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435", //No I18n
	"Attended Dialled" : "\u041e\u0431\u0440\u0430\u0431\u043e\u0442\u0430\u043d\u043d\u044b\u0435 \u043d\u0430\u0431\u0440\u0430\u043d\u043d\u044b\u0435 \u043d\u043e\u043c\u0435\u0440\u0430", //No I18n
	"Unattended Dialled" : "\u041d\u0435\u043e\u0431\u0440\u0430\u0431\u043e\u0442\u0430\u043d\u043d\u044b\u0435 \u043d\u0430\u0431\u0440\u0430\u043d\u043d\u044b\u0435 \u043d\u043e\u043c\u0435\u0440\u0430", //No I18n
	"Overdue" : "\u041f\u0440\u043e\u0441\u0440\u043e\u0447\u0435\u043d\u043d\u044b\u0435", //No I18n
	"Cancelled" : "\u041e\u0442\u043c\u0435\u043d\u0435\u043d\u043e", //No I18n
	"Received" : "\u041f\u043e\u043b\u0443\u0447\u0435\u043d\u043e", //No I18n
	"Missed" : "\u041f\u0440\u043e\u043f\u0443\u0449\u0435\u043d\u043d\u044b\u0435", //No I18n
	"crm.alert.character.not.allowed" : "\u0418\u0441\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u043d\u0438\u0435 {0} \u043d\u0435 \u0434\u043e\u043f\u0443\u0441\u043a\u0430\u0435\u0442\u0441\u044f", //No I18n
	"crm.condition.in.last" : "\u0432 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0435", //No I18n
	"crm.zinvoice.dueIn" : "\u0432\u044b\u043f\u043e\u043b\u043d\u0438\u0442\u044c \u0437\u0430", //No I18n
	"on" : "\u041a\u043e\u0433\u0434\u0430:",//No I18n
	"before" : "\u043f\u0435\u0440\u0435\u0434",//No I18n
	"crm.label.general.small.after" : "\u043f\u043e\u0441\u043b\u0435",//No I18n
	"crm.thisweek" : "\u0422\u0435\u043a\u0443\u0449\u0430\u044f \u043d\u0435\u0434\u0435\u043b\u044f",//No I18n
	"crm.label.this.month" : "\u0412 \u044d\u0442\u043e\u043c \u043c\u0435\u0441\u044f\u0446\u0435",//No I18n
	"crm.thisyear" : "\u0412 \u044d\u0442\u043e\u043c \u0433\u043e\u0434\u0443",//No I18n
	"crm.source.user.and.system" : "\u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u0438 \u0441\u0438\u0441\u0442\u0435\u043c\u0430",//No I18n
	"crm.source.user.or.system" : "\u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u0438\u043b\u0438 \u0441\u0438\u0441\u0442\u0435\u043c\u0430",//No I18n
	"crm.label.system2" : "\u0421\u0438\u0441\u0442\u0435\u043c\u0430",//No I18n
	"crm.source.user.only" : "\u0422\u043e\u043b\u044c\u043a\u043e \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c",//No I18n
	"crm.source.system.only" : "\u0422\u043e\u043b\u044c\u043a\u043e \u0441\u0438\u0441\u0442\u0435\u043c\u0430",//No I18n
	"crm.condition.till.today" : "\u0414\u043e \u0441\u0435\u0433\u043e\u0434\u043d\u044f\u0448\u043d\u0435\u0433\u043e \u0434\u043d\u044f",//No I18n
	"game.month.left" : "1\u00a0\u043c\u0435\u0441\u044f\u0446",//No I18n
	"game.months.left" : "{0}\u00a0\u043c\u0435\u0441.",//No I18n
	"crm.condition.last.30.days" : "\u0437\u0430 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0435 30 \u0434\u043d\u0435\u0439",//No I18n
	"crm.condition.last.60.days" : "\u0437\u0430 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0435 60 \u0434\u043d\u0435\u0439",//No I18n
	"crm.condition.last.90.days" : "\u0437\u0430 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0435 90 \u0434\u043d\u0435\u0439",//No I18n
	"crm.label.filter.typehere" : "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0442\u0435\u043a\u0441\u0442", //No I18N
	"crm.filter.is.not" : "\u043d\u0435", //No I18n
	"crm.condition.until.now" : "\u0414\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0435\u0433\u043e \u043c\u043e\u043c\u0435\u043d\u0442\u0430",//No I18n
	"crm.filter.email.isblocked" : "\u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d",//No I18n
	"crm.filter.email.isnotblocked" : "\u043d\u0435 \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d",//No I18n
	"crm.label.no.results.match" : "\u0420\u0435\u0437\u0443\u043b\u044c\u0442\u0430\u0442\u044b \u043d\u0435 \u043d\u0430\u0439\u0434\u0435\u043d\u044b",//No I18n
	"crm.label.select.user" : "\u041d\u0430\u0436\u043c\u0438\u0442\u0435, \u0447\u0442\u043e\u0431\u044b \u0432\u044b\u0431\u0440\u0430\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0435\u0439.", //No I18n
	"current.logged.in.user": "\u0412\u043e\u0448\u0435\u0434\u0448\u0438\u0439 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c", //NO I18n
	"current.logged.in.user.definition": "\u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c, \u0438\u043d\u0438\u0446\u0438\u0438\u0440\u0443\u044e\u0449\u0438\u0439 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435 \u0437\u0430\u043f\u0438\u0441\u0438.", //NO i18n
	"crm.security.group": "\u0413\u0440\u0443\u043f\u043f\u0430", //NO I18n
	"crm.security.role": "\u0420\u043e\u043b\u044c", //NO I18n
	"Date" : "\u0414\u0430\u0442\u0430",//No I18n
	"crm.field.valid.decimal.check2" : "\u041a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0434\u0435\u0441\u044f\u0442\u0438\u0447\u043d\u044b\u0445 \u0440\u0430\u0437\u0440\u044f\u0434\u043e\u0432 \u0434\u043b\u044f \u043f\u043e\u043b\u044f <i>{0}</i> \u0434\u043e\u043b\u0436\u043d\u043e \u0431\u044b\u0442\u044c {1} \u0438\u043b\u0438 \u043c\u0435\u043d\u0435\u0435.",//No I18n
	"crm.field.empty.check" : "\u0417\u043d\u0430\u0447\u0435\u043d\u0438\u0435 \u043f\u043e\u043b\u044f {0} \u043d\u0435 \u043c\u043e\u0436\u0435\u0442 \u0431\u044b\u0442\u044c \u043f\u0443\u0441\u0442\u044b\u043c.",//No I18n
	"crm.label.add.note": "\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435", //NO I18n
	"crm.label.simply.by": "\u043e\u0442", //NO I18n
	"crm.general.addnote": "\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435", //NO I18n
	"crm.general.addtitle": "\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u0437\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a", //NO I18n
	"crm.label.attach.file": "\u041f\u0440\u0438\u043a\u0440\u0435\u043f\u0438\u0442\u044c \u0444\u0430\u0439\u043b", //NO I18N
	"crm.button.mass.delete": "\u0423\u0434\u0430\u043b\u0438\u0442\u044c", //NO I18N
	"crm.warning.delete.record": "\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043b\u044c\u043d\u043e \u043f\u0435\u0440\u0435\u043c\u0435\u0441\u0442\u0438\u0442\u044c \"{0}\" \u0432 \u043a\u043e\u0440\u0437\u0438\u043d\u0443?", //NO I18N
	"crm.label.yes": "\u0414\u0430", //NO I18N
	"crm.note.view.previous": "\u041f\u0440\u043e\u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u043f\u0440\u0435\u0434\u044b\u0434\u0443\u0449\u0438\u0435 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u044f", //NO I18N
  "of": "\u0438\u0437", //NO I18N
	"crm.label.notes": "\u041f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u044f", //NO I18N
	"crm.note.recent.first": "\u0421\u043d\u0430\u0447\u0430\u043b\u0430 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0435", //NO I18N
	"crm.note.recent.last": "\u041f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0435 \u0432 \u043a\u043e\u043d\u0446\u0435", //NO I18N
	"crm.territory.label.only": "\u0422\u043e\u043b\u044c\u043a\u043e {0}", //no i18n
	"crm.select" : "\u0412\u044b\u0431\u0440\u0430\u0442\u044c",//No I18n
	"crm.button.apply.filter" : "\u041f\u0440\u0438\u043c\u0435\u043d\u0438\u0442\u044c \u0444\u0438\u043b\u044c\u0442\u0440",//No I18n
	"crm.alert.maximum.text.values.contains" : "\u0412 \u044d\u0442\u043e\u043c \u043f\u043e\u043b\u0435 \u043c\u043e\u0436\u043d\u043e \u0443\u043a\u0430\u0437\u0430\u0442\u044c \u043e\u043f\u0440\u0435\u0434\u0435\u043b\u0435\u043d\u043d\u043e\u0435 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0437\u043d\u0430\u0447\u0435\u043d\u0438\u0439 (\u043d\u0435 \u0431\u043e\u043b\u0435\u0435 {0}).",//No I18n
	"PM" : "PM",//No I18n
	//start-cx create form keys
	"crm.duplicate.value.not.allowed": "\u041f\u043e\u0432\u0442\u043e\u0440\u044f\u044e\u0449\u0438\u0435\u0441\u044f \u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0435 \u0434\u043e\u043f\u0443\u0441\u043a\u0430\u044e\u0442\u0441\u044f.",//no i18n
	"crm.duplicate.value.available": "{0} \u0441 \u0442\u0430\u043a\u0438\u043c {1} \u0443\u0436\u0435 \u0441\u0443\u0449\u0435\u0441\u0442\u0432\u0443\u0435\u0442.",//no i18n
	"crm.duplicate.value.available.multiple": "\u041e\u0431\u043d\u0430\u0440\u0443\u0436\u0435\u043d\u043e \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e {0} \u0441 \u0442\u0430\u043a\u0438\u043c {1}.",//no i18n
	"crm.custombutton.nobuttons.found": "\u041a\u043d\u043e\u043f\u043a\u0438 \u043d\u0435 \u043d\u0430\u0439\u0434\u0435\u043d\u044b",//no i18n
	"crm.custombutton.create.newbutton": "\u0421\u043e\u0437\u0434\u0430\u0442\u044c \u043a\u043d\u043e\u043f\u043a\u0443",//no i18n
	"crm.custombutton.manage.button": "\u0423\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u043d\u043e\u043f\u043a\u0430\u043c\u0438",//no i18n
	"crm.custombutton.name": "\u0418\u043c\u044f",//no i18n
	"crm.customize.custombutton.function.desc": "\u041e\u043f\u0438\u0441\u0430\u043d\u0438\u0435",//no i18n
	"crm.custombutton.action": "\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u0435 \u043f\u0440\u0438 \u043d\u0430\u0436\u0430\u0442\u0438\u0438 \u043a\u043d\u043e\u043f\u043a\u0438",//no i18n
	"crm.custombutton.empty.field.values": "\u0412 \u0441\u043b\u0435\u0434\u0443\u044e\u0449\u0438\u0445 \u043f\u043e\u043b\u044f\u0445 \u043e\u0431\u043d\u0430\u0440\u0443\u0436\u0435\u043d\u044b \u043f\u0443\u0441\u0442\u044b\u0435 \u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f:",//no i18n
	"crm.custombutton.proceed.action": "\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043b\u044c\u043d\u043e \u043f\u0440\u043e\u0434\u043e\u043b\u0436\u0438\u0442\u044c?",//no i18n
	"zb.common.Cancel": "\u041e\u0442\u043c\u0435\u043d\u0430",//no i18n
	"crm.yes.proceed": "\u0414\u0430, \u043f\u0440\u043e\u0434\u043e\u043b\u0436\u0438\u0442\u044c.",//no i18n
	"crm.label.module.merge": "\u041e\u0431\u044a\u0435\u0434\u0438\u043d\u0438\u0442\u044c {0}",//no i18n
	"crm.view.record": "\u041f\u0440\u043e\u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c {0}",//no i18n
	"crm.tpi.ctiapi.config.choosefile": "\u0412\u044b\u0431\u0435\u0440\u0438\u0442\u0435 \u0444\u0430\u0439\u043b",//no i18n
	"crm.label.subform.addrows": "\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u0441\u0442\u0440\u043e\u043a\u0443",//no i18n
	"crm.button.save&new": "\u0421\u043e\u0445\u0440\u0430\u043d\u0438\u0442\u044c \u0438 \u0441\u043e\u0437\u0434\u0430\u0442\u044c",//no i18n
	//end-cx create form keys
	"crm.mb.newversion.msg4" : "\u041e\u041a, \u043f\u043e\u043d\u044f\u0442\u043d\u043e.",//No I18n
	"Jan" : "\u042f\u043d\u0432",//No I18n
	"Feb" : "\u0424\u0435\u0432",//No I18n
	"Mar" : "\u041c\u0430\u0440",//No I18n
	"Apr" : "\u0410\u043f\u0440",//No I18n
	"Jun" : "\u0418\u044e\u043d",//No I18n
	"Jul" : "\u0418\u044e\u043b",//No I18n
	"Aug" : "\u0410\u0432\u0433",//No I18n
	"Sep" : "\u0421\u0435\u043d",//No I18n
	"Oct" : "\u041e\u043a\u0442",//No I18n
	"Nov" : "\u041d\u043e\u044f",//No I18n
	"Dec" : "\u0414\u0435\u043a",//No I18n
	"crm.label.More" :"\u0415\u0449\u0435", //no i18n

	"crm.label.unmapped.stages":"\u041d\u0435\u0443\u0447\u0442\u0435\u043d\u043d\u044b\u0435", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "\u041f\u043e\u043a\u0430\u0437\u0430\u0442\u044c \u0435\u0449\u0435",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "\u041f\u043e\u043a\u0430\u0437\u0430\u0442\u044c \u043c\u0435\u043d\u044c\u0448\u0435",//No I18n

	 	 //filter related keys-start
"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
"crm.lead.prediction.tooltip.convert":"\u0412\u0415\u0420\u041e\u042f\u0422\u041d\u041e\u0421\u0422\u042c",//no i18n
"crm.lead.prediction.tooltip.score":"\u041e\u0426\u0415\u041d\u041a\u0410",//no i18n
"Planned":"\u0417\u0430\u043f\u043b\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u043e",//no i18n
"Invited":"\u041f\u0440\u0438\u0433\u043b\u0430\u0448\u0435\u043d\u0438\u0435 \u043e\u0442\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043e",//no i18n
"Sent":"\u041e\u0442\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043e",//no i18n
"Received":"\u041f\u043e\u043b\u0443\u0447\u0435\u043d\u043e",//no i18n
"Opened":"\u041e\u0442\u043a\u0440\u044b\u0442",//no i18n
"Responded":"\u041e\u0442\u0432\u0435\u0442 \u043e\u0442\u043f\u0440\u0430\u0432\u043b\u0435\u043d",//no i18n
"Bounced":"\u0412\u043e\u0437\u0432\u0440\u0430\u0449\u0435\u043d\u043e",//no i18n
"Opted\ Out":"\u041e\u0442\u043a\u0430\u0437 \u043e\u0442 \u0438\u0441\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u043d\u0438\u044f",//no i18n
"crm.filter.label.with.open":"\u0421 \u043e\u0442\u043a\u0440\u044b\u0442\u044b\u043c\u0438 {0}",//no i18n
"crm.filter.label.without.open":"\u0411\u0435\u0437 \u043e\u0442\u043a\u0440\u044b\u0442\u044b\u0445 {0}",//no i18n
"crm.filter.label.without.any":"\u0411\u0435\u0437 {0}",//no i18n
"crm.filter.label.with.module":"\u0421 {0}",//no i18n
"crm.filter.label.activity.due":"\u0417\u0430\u043f\u043b\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u043d\u0430 {0}",//no i18n
"crm.filter.label.activity.done":"\u0413\u043e\u0442\u043e\u0432\u044b\u0435 {0}",//no i18n
"Notes":"\u041f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u044f",//no i18n
"crm.filter.label.notes.added":"\u0414\u043e\u0431\u0430\u0432\u043b\u0435\u043d\u044b \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u044f",//no i18n
"crm.label.filter.email.status":"\u041f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0439 \u0441\u0442\u0430\u0442\u0443\u0441 \u043f\u043e\u0447\u0442\u044b",//no i18n
"crm.label.filter.email.clicked":"\u043f\u0435\u0440\u0435\u0445\u043e\u0434",//no i18n
"crm.label.filter.email.responded":"\u043f\u0440\u0435\u0434\u043e\u0441\u0442\u0430\u0432\u043b\u0435\u043d \u043e\u0442\u0432\u0435\u0442", //no i18n
"crm.label.filter.email.info":"\u0424\u0438\u043b\u044c\u0442\u0440\u0430\u0446\u0438\u044f \u0437\u0430\u043f\u0438\u0441\u0435\u0439 \u043d\u0430 \u043e\u0441\u043d\u043e\u0432\u0435 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0435\u0433\u043e \u0441\u0442\u0430\u0442\u0443\u0441\u0430 \u0432\u0430\u0448\u0438\u0445 \u043e\u0442\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043d\u044b\u0445/\u043f\u043e\u043b\u0443\u0447\u0435\u043d\u043d\u044b\u0445 \u043f\u0438\u0441\u0435\u043c.",//no i18n
"crm.filter.label.sent":"\u043e\u0442\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043d\u044b\u0435",//no i18n
"crm.filter.label.not.sent":"\u043d\u0435\u043e\u0442\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043d\u044b\u0435",//no i18n
"crm.filter.label.opened":"\u043e\u0442\u043a\u0440\u044b\u0442\u044b\u0435",//no i18n
"crm.filter.label.not.opened":"\u043d\u0435\u043e\u0442\u043a\u0440\u044b\u0442\u044b\u0435",//no i18n
"crm.filter.label.received":"\u043f\u043e\u043b\u0443\u0447\u0435\u043d\u043d\u044b\u0435",//no i18n
"crm.filter.label.not.received":"\u043d\u0435\u043f\u043e\u043b\u0443\u0447\u0435\u043d\u043d\u044b\u0435",//no i18n
"crm.filter.label.bounced":"\u0432\u043e\u0437\u0432\u0440\u0430\u0449\u0435\u043d\u043d\u044b\u0435",//no i18n
"crm.filter.label.opened.not.replied":"\u043e\u0442\u043a\u0440\u044b\u0442\u044b\u0435 \u0438 \u043d\u0435\u043e\u0442\u0432\u0435\u0447\u0435\u043d\u043d\u044b\u0435", //no i18n
"crm.filter.label.any":"\u041b\u044e\u0431\u043e\u0435 \u0438\u0437 \u0443\u043a\u0430\u0437\u0430\u043d\u043d\u043e\u0433\u043e",//no i18n
"crm.zia.config.potential.amount":"\u041e\u0431\u044a\u0435\u043c {0}",//no i18n
"Quote\ Stage":"\u0421\u0442\u0430\u0434\u0438\u044f {0}",//no i18n
"crm.module.owner":"{0} - \u043e\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439",//no i18n
"Potential\ Closing\ Date":"{0} - \u0434\u0430\u0442\u0430 \u0437\u0430\u043a\u043b\u044e\u0447\u0435\u043d\u0438\u044f",//no i18n
"crm.lead.prediction.likely.convert":"\u0412\u043e\u0437\u043c\u043e\u0436\u043d\u043e \u043f\u0440\u0435\u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u0435",//no i18n
"crm.lead.prediction.convert.high":"\u0412\u044b\u0441\u043e\u043a\u043e\u0435",//no i18n
"crm.lead.prediction.convert.medium":"\u0421\u0440\u0435\u0434\u043d\u0435\u0435",//no i18n
"crm.lead.prediction.convert.low":"\u041d\u0438\u0437\u043a\u043e\u0435",//no i18n
"crm.predictions.feature.label":"\u041f\u0440\u043e\u0433\u043d\u043e\u0437",//no i18n
"crm.intelligence.prediction.likelywin":"\u0412\u043e\u0437\u043c\u043e\u0436\u043d\u0430 \u043f\u043e\u0431\u0435\u0434\u0430",//no i18n
"crm.intelligence.prediction.likelylose":"\u0412\u043e\u0437\u043c\u043e\u0436\u0435\u043d \u043f\u0440\u043e\u0438\u0433\u0440\u044b\u0448",//no i18n
"crm.intelligence.prediction.halfchance":"50:50",//no i18n
"crm.intelligence.prediction.score":"\u041f\u0440\u043e\u0433\u043d\u043e\u0437\u0438\u0440\u0443\u0435\u043c\u044b\u0435 \u0431\u0430\u043b\u043b\u044b",//no i18n
"crm.lead.prediction.recent.score":"\u041f\u043e\u0441\u043b\u0435\u0434\u043d\u044f\u044f \u043f\u0440\u043e\u0433\u043d\u043e\u0437\u0438\u0440\u0443\u0435\u043c\u0430\u044f \u043e\u0446\u0435\u043d\u043a\u0430",//no i18n
"crm.intelligence.prediction.lastconv":"3\u00a0\u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0435 \u0431\u0435\u0441\u0435\u0434\u044b",//no i18n
"crm.intelligence.prediction.recordsfocus":"\u0412\u0430\u0436\u043d\u044b\u0435 \u0437\u0430\u043f\u0438\u0441\u0438",//no i18n
"crm.intelligence.prediction.slowmoving":"\u041c\u0435\u0434\u043b\u0435\u043d\u043d\u043e\u0435 \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435",//no i18n
"crm.intelligence.prediction.trend.down":"\u0412 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0435\u0435 \u0432\u0440\u0435\u043c\u044f \u0438\u043c\u0435\u0435\u0442\u0441\u044f \u043d\u0435\u0433\u0430\u0442\u0438\u0432\u043d\u0430\u044f \u0442\u0435\u043d\u0434\u0435\u043d\u0446\u0438\u044f",//no i18n
"crm.label.touched.records":"\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u0437\u0430\u043f\u0438\u0441\u0438",//no i18n
"crm.label.untouched.records":"\u0417\u0430\u043f\u0438\u0441\u0438 \u0431\u0435\u0437 \u0438\u0437\u043c\u0435\u043d\u0435\u043d\u0438\u0439",//no i18n
"crm.label.record.action":"\u0417\u0430\u043f\u0438\u0441\u0430\u0442\u044c \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435",//no i18n
"workflow.rule.view.label.Modified":"\u0418\u0437\u043c\u0435\u043d\u0435\u043d\u043e",//no i18n
"crm.label.not.modified":"\u041d\u0435 \u0438\u0437\u043c\u0435\u043d\u0435\u043d\u043e",//no i18n
"crm.label.related.records.action":"\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u044f \u0434\u043b\u044f \u0441\u0432\u044f\u0437\u0430\u043d\u043d\u044b\u0445 \u0437\u0430\u043f\u0438\u0441\u0435\u0439",//no i18n
"Done":"\u0413\u043e\u0442\u043e\u0432\u043e",//no i18n
"crm.label.not.done":"\u041d\u0435 \u0433\u043e\u0442\u043e\u0432\u043e",//no i18n
"sentiment.model":"\u0422\u043e\u043d \u043f\u0438\u0441\u044c\u043c\u0430",//no i18n
"sentiment.criteria.count":"\u041a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e",//no i18n
"sentiment.criteria.percentage":"\u041f\u0440\u043e\u0446\u0435\u043d\u0442",//no i18n
"sentiment.criteria.lastmail":"\u0414\u043b\u044f \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0435\u0433\u043e \u043f\u0438\u0441\u044c\u043c\u0430",//no i18n
"Chats":"\u0427\u0430\u0442\u044b",//no i18n
"Attended":"\u041e\u0431\u0440\u0430\u0431\u043e\u0442\u0430\u043d\u043e",//no i18n
"crm.lead.prediction.popup.text":"\u041a\u043e\u0433\u0434\u0430 \u0437\u043d\u0430\u0447\u0435\u043d\u0438\u0435 \u043f\u0430\u0440\u0430\u043c\u0435\u0442\u0440\u0430 \u201c\u0412\u043e\u0437\u043c\u043e\u0436\u043d\u043e \u043f\u0440\u0435\u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u0435\u201d \u0441\u043e\u0441\u0442\u0430\u0432\u043b\u044f\u0435\u0442 {0}, \u043f\u0440\u043e\u0433\u043d\u043e\u0437\u0438\u0440\u0443\u0435\u043c\u0430\u044f \u043e\u0446\u0435\u043d\u043a\u0430 \u0434\u043e\u043b\u0436\u043d\u0430 \u0431\u044b\u0442\u044c \u0432 \u043f\u0440\u0435\u0434\u0435\u043b\u0430\u0445 {1}.",//no i18n
"crm.lead.prediction.popup.final":"\u0418\u0437\u043c\u0435\u043d\u0438\u0442\u0435 \u0444\u0438\u043b\u044c\u0442\u0440 \u0441\u043e\u043e\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0443\u044e\u0449\u0438\u043c \u043e\u0431\u0440\u0430\u0437\u043e\u043c \u0438 \u043f\u043e\u0432\u0442\u043e\u0440\u0438\u0442\u0435 \u043f\u043e\u043f\u044b\u0442\u043a\u0443.",//no i18n
"crm.custom.field.less.than.to1":"\u041d\u0430\u0447\u0430\u043b\u044c\u043d\u044b\u0439 \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d \u0434\u043e\u043b\u0436\u0435\u043d \u0431\u044b\u0442\u044c \u043c\u0435\u043d\u044c\u0448\u0435 \u043a\u043e\u043d\u0435\u0447\u043d\u043e\u0433\u043e \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d\u0430.",//no i18n
"Last\ Activity\ Date":"\u0414\u0430\u0442\u0430 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0435\u0433\u043e \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044f",//no i18n
"crm.label.vendor.name":"{0} - \u0438\u043c\u044f",//no i18n
"hours":"\u0447",//no i18n
"days":"\u0434\u043d.",//no i18n
"weeks":"\u043d\u0435\u0434\u0435\u043b\u044c",//no i18n
"months":"\u043c\u0435\u0441\u044f\u0446\u0435\u0432",//no i18n
"years":"\u0433\u043e\u0434 (\u043b\u0435\u0442)",//no i18n
"crm.label.general.small.after":"\u043f\u043e\u0441\u043b\u0435",//no i18n
"Last\ Week":"\u041f\u0440\u043e\u0448\u043b\u0430\u044f \u043d\u0435\u0434\u0435\u043b\u044f",//no i18n
"Last\ Month":"\u041f\u0440\u043e\u0448\u043b\u044b\u0439 \u043c\u0435\u0441\u044f\u0446",//no i18n
"crm.module.name":"{0} - \u0438\u043c\u044f",//no i18n
"Campaign":"\u041a\u0430\u043c\u043f\u0430\u043d\u0438\u044f",//no i18n
"Tasks":"\u0417\u0430\u0434\u0430\u0447\u0438",//no i18n
"Calls":"\u0412\u044b\u0437\u043e\u0432\u044b",//no i18n
"Events":"\u041c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u044f",//no i18n
"sentiment.criteria.wrongcriteria":"\u0417\u043d\u0430\u0447\u0435\u043d\u0438\u0435 \u043a\u0440\u0438\u0442\u0435\u0440\u0438\u044f \u043d\u0435 \u0434\u043e\u043b\u0436\u043d\u043e \u043f\u0440\u0435\u0432\u044b\u0448\u0430\u0442\u044c {0}",//no i18n
"crm.chosen.minimum.input.text":"\u0412\u0432\u0435\u0434\u0438\u0442\u0435 {0} \u0438\u043b\u0438 \u0431\u043e\u043b\u0435\u0435 \u0441\u0438\u043c\u0432\u043e\u043b\u043e\u0432",//no i18n
"crm.intelligence.prediction.trendup":"\u0422\u0435\u043d\u0434\u0435\u043d\u0446\u0438\u044f \u043a \u0440\u043e\u0441\u0442\u0443",//no i18n
"crm.intelligence.prediction.trenddown":"\u0422\u0435\u043d\u0434\u0435\u043d\u0446\u0438\u044f \u043a \u0441\u043f\u0430\u0434\u0443",//no i18n
"crm.zia.prediction.notrend":"No trend",//no i18n
"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
"Completed":"\u0417\u0430\u0432\u0435\u0440\u0448\u0435\u043d\u043e" ,//no i18n
"crm.label.success":"\u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e" ,//no i18n
"crm.label.Failure":"\u041e\u0448\u0438\u0431\u043a\u0430" ,//no i18n
"Both":"\u041e\u0431\u0430" ,//no i18n
"crm.condition.cannot.empty":"\u041d\u0435\u043e\u0431\u0445\u043e\u0434\u0438\u043c\u043e \u0443\u043a\u0430\u0437\u0430\u0442\u044c \u0443\u0441\u043b\u043e\u0432\u0438\u0435.",//no i18n
"crm.condition.last.30.days":"\u0437\u0430 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0435 30 \u0434\u043d\u0435\u0439",//no i18n
"crm.condition.last.60.days":"\u0437\u0430 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0435 60 \u0434\u043d\u0435\u0439",//no i18n
"crm.condition.last.90.days":"\u0437\u0430 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0435 90 \u0434\u043d\u0435\u0439",//no i18n
"crm.sentiment.Positive":"\u041f\u043e\u043b\u043e\u0436\u0438\u0442\u0435\u043b\u044c\u043d.",//no i18n
"crm.sentiment.Negative":"\u041e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u044c\u043d.",//no i18n
"sentiment.positiveandnegative":"\u041f\u043e\u043b\u043e\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u0435 \u0438 \u043e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u044c\u043d\u044b\u0435",//no i18n
"sentiment.positiveornegative":"\u041f\u043e\u043b\u043e\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u0435 \u0438\u043b\u0438 \u043e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u044c\u043d\u044b\u0435",//no i18n
"sentiment.positiveonly":"\u0422\u043e\u043b\u044c\u043a\u043e \u043f\u043e\u043b\u043e\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u0435",//no i18n
"sentiment.negativeonly":"\u0422\u043e\u043b\u044c\u043a\u043e \u043e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u044c\u043d\u044b\u0435",//no i18n
"crm.sentiment.Neutral":"\u041d\u0435\u0439\u0442\u0440\u0430\u043b\u044c\u043d.",//no i18n
"crm.filters.select.campaign.type":"\u0412\u044b\u0431\u0435\u0440\u0438\u0442\u0435 \u0442\u0438\u043f {0}",//no i18n
"crm.filters.select.campaign.status":"\u0412\u044b\u0431\u0435\u0440\u0438\u0442\u0435 \u0441\u0442\u0430\u0442\u0443\u0441 {0}",//no i18n
"campaign.Member" : "\u0423\u0447\u0430\u0441\u0442\u043d\u0438\u043a",//no i18n
	"Service":"\u0421\u043b\u0443\u0436\u0431\u0430",//no i18n
"Activities":"\u041e\u043f\u0435\u0440\u0430\u0446\u0438\u0438",//no i18n
"crm.livedesk.pot.nextdays":"\u0421\u043b\u0435\u0434\u0443\u044e\u0449\u0438\u0435 {0}\u00a0\u0434\u043d.",//no i18n
"Today\ +\ Overdue":"\u0421\u0435\u0433\u043e\u0434\u043d\u044f\u0448\u043d\u0438\u0435 + \u043f\u0440\u043e\u0441\u0440\u043e\u0447\u0435\u043d\u043d\u044b\u0435",//no i18n
"crm.source.user.and.system":"\u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u0438 \u0441\u0438\u0441\u0442\u0435\u043c\u0430",//no i18n
"crm.source.user.or.system":"\u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u0438\u043b\u0438 \u0441\u0438\u0441\u0442\u0435\u043c\u0430",//no i18n
"User":"\u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c",//no i18n
"crm.source.user.only":"\u0422\u043e\u043b\u044c\u043a\u043e \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c",//no i18n
"crm.source.system.only":"\u0422\u043e\u043b\u044c\u043a\u043e \u0441\u0438\u0441\u0442\u0435\u043c\u0430",//no i18n
"Scheduled":"\u0417\u0430\u043f\u043b\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435",//no i18n
"Attended\ Dialled":"\u041e\u0431\u0440\u0430\u0431\u043e\u0442\u0430\u043d\u043d\u044b\u0435 \u043d\u0430\u0431\u0440\u0430\u043d\u043d\u044b\u0435 \u043d\u043e\u043c\u0435\u0440\u0430",//no i18n
"Unattended\ Dialled":"\u041d\u0435\u043e\u0431\u0440\u0430\u0431\u043e\u0442\u0430\u043d\u043d\u044b\u0435 \u043d\u0430\u0431\u0440\u0430\u043d\u043d\u044b\u0435 \u043d\u043e\u043c\u0435\u0440\u0430",//no i18n
"Cancelled":"\u041e\u0442\u043c\u0435\u043d\u0435\u043d\u043e",//no i18n
"crm.filter.email.isblocked":"\u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d",//no i18n
"crm.filter.email.isnotblocked":"\u043d\u0435 \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d",//no i18n
"condition.till.now":"\u0414\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0435\u0433\u043e \u043c\u043e\u043c\u0435\u043d\u0442\u0430",//no i18n
"crm.recurring.no.months":"{0} \u043c\u0435\u0441.",//no i18n
"crm.lead.prediction.tooltip":"\u0412\u043e\u0437\u043c\u043e\u0436\u043d\u043e \u043f\u0440\u0435\u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u0435\u00a0- \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d \u043e\u0446\u0435\u043d\u043e\u043a",//no i18n
"crm.website.activity":"\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u044f \u043d\u0430 \u0432\u0435\u0431-\u0441\u0430\u0439\u0442\u0435",//no i18n
"crm.label.By":"\u041a\u0435\u043c",//no i18n
"crm.chosen.searching.text":"\u041f\u043e\u0438\u0441\u043a...",//no i18n
"crm.label.memberstatus.is":"\u0438 \u0441\u0442\u0430\u0442\u0443\u0441 \u0443\u0447\u0430\u0441\u0442\u043d\u0438\u043a\u0430\u00a0-",//no i18n
"crm.events.duration":"\u0414\u043b\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c",//no i18n
"crm.title.clear.name":"\u041e\u0447\u0438\u0441\u0442\u0438\u0442\u044c",//no i18n
"crm.label.status.is":"\u0441\u043e \u0441\u0442\u0430\u0442\u0443\u0441\u043e\u043c",//no i18n
"zia.last3.help":"\u0411\u0435\u0441\u0435\u0434\u0430 \u0432\u043a\u043b\u044e\u0447\u0430\u0435\u0442 \u0432\u044b\u0437\u043e\u0432\u044b, \u0437\u0430\u0434\u0430\u0447\u0438, {0}, \u043f\u043e\u043b\u0443\u0447\u0435\u043d\u043d\u044b\u0435 \u043f\u0438\u0441\u044c\u043c\u0430, \u0437\u0430\u043c\u0435\u0442\u043a\u0438, \u0432\u0438\u0437\u0438\u0442\u044b, \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0438 \u0432 \u0441\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0445 \u0441\u0435\u0442\u044f\u0445, \u0437\u0430\u043f\u0440\u043e\u0441\u044b \u0432 \u0441\u043b\u0443\u0436\u0431\u0443 \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u043a\u0438 \u0438\u0437 Desk.",//no i18n
"crm.label.tag.related.to":"\u0441\u0432\u044f\u0437\u0430\u043d \u0441",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "\u0411\u0443\u0434\u0435\u0442 \u0441\u043e\u0437\u0434. \u043d\u043e\u0432. {0}.",//No I18n
	"crm.krp.no.records.found" : "\u041d\u0435 \u0443\u0434\u0430\u043b\u043e\u0441\u044c \u043d\u0430\u0439\u0442\u0438 {0}",//No I18n
	"crm.module.new" : "\u041d\u043e\u0432. {0}",//No I18n
	"crm.label.view" : "\u041f\u0440\u043e\u0441\u043c\u043e\u0442\u0440",//No I18n
	"crm.nsocial.customers" : "\u041a\u043b\u0438\u0435\u043d\u0442\u044b",//No I18n
	"crm.nsocial.open.potential" : "\u041e\u0442\u043a\u0440\u044b\u0442\u044c {0}",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "\u041f\u0440\u043e\u0447\u0438\u0435",//No i18n
	"crm.field.length.check" : "\u0414\u043b\u0438\u043d\u0430 \u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u0432 \u043f\u043e\u043b\u0435 {0} \u043f\u0440\u0435\u0432\u044b\u0448\u0430\u0435\u0442 \u043c\u0430\u043a\u0441\u0438\u043c\u0430\u043b\u044c\u043d\u043e \u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0443\u044e.", //No I18n
	"crm.lower.now": "\u0441\u0435\u0439\u0447\u0430\u0441",//no i18n
	"crm.time.min.ago": "{0} \u043c\u0438\u043d \u043d\u0430\u0437\u0430\u0434",//no i18n
	"crm.time.mins.ago":"{0} \u043c\u0438\u043d \u043d\u0430\u0437\u0430\u0434",//no i18n
	"crm.time.hr.ago": "{0} \u0447 \u043d\u0430\u0437\u0430\u0434",//no i18n
	"crm.time.hrs.ago": "{0} \u0447 \u043d\u0430\u0437\u0430\u0434", //no i18n
	"AllUsers": "\u0412\u0441\u0435 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0438" ,//no i18n
	"crm.label.search":"\u041f\u043e\u0438\u0441\u043a",//no i18n
	"crm.api.filterby":"\u0424\u0438\u043b\u044c\u0442\u0440 \u043f\u043e",//no i18n
	"crm.customview.nofields.found":"--\u041d\u0435\u0442 \u043f\u043e\u0434\u0445\u043e\u0434\u044f\u0449\u0438\u0445 \u043f\u043e\u043b\u0435\u0439--",//no i18n
	"crm.setup.system.ziarecommendation":"\u0420\u0435\u043a\u043e\u043c\u0435\u043d\u0434\u0430\u0446\u0438\u044f",//no i18n
	"crm.filter.label.all.products":"\u0412\u0441\u0435 {0}",//no i18n
	"crm.filter.label.select.products":"\u0412\u044b\u0431\u0440\u0430\u043d\u043d\u044b\u0435 {0}",//no i18n
	"crm.reviewprocess.smart.filter":"\u0421\u0442\u0430\u0442\u0443\u0441 \u0437\u0430\u043f\u0438\u0441\u0438 \u0432 \u043f\u0440\u043e\u0446\u0435\u0441\u0441\u0435 \u0440\u0430\u0441\u0441\u043c\u043e\u0442\u0440\u0435\u043d\u0438\u044f",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"\u0412\u044b\u0431\u0435\u0440\u0438\u0442\u0435 \u0437\u043d\u0430\u0447\u0435\u043d\u0438\u0435.",//no i18n
	"crm.segmentation.segment.score":"\u041e\u0446\u0435\u043d\u043a\u0430 \u0441\u0435\u0433\u043c\u0435\u043d\u0442\u0430",//no i18n
	"crm.filter.label.in":"\u0441 \u0443\u0447\u0435\u0442\u043e\u043c",//no i18n
	"crm.filter.label.and.purchase.in":"\u0438 \u0441 \u0431\u043e\u043b\u044c\u0448\u043e\u0439 \u0432\u0435\u0440\u043e\u044f\u0442\u043d\u043e\u0441\u0442\u044c\u044e \u0441\u043e\u0432\u0435\u0440\u0448\u0438\u0442 \u043f\u043e\u043a\u0443\u043f\u043a\u0443 \u0432",//no i18n
	"crm.filter.label.last.purchased":"\u0438 \u043d\u0435\u0434\u0430\u0432\u043d\u043e \u043f\u0440\u0438\u043e\u0431\u0440\u0435\u043b",//no i18n
	"crm.filter.label.a.day":"\u0432 \u0434\u0435\u043d\u044c",//no i18n
	"crm.filter.label.a.week":"\u0432 \u043d\u0435\u0434\u0435\u043b\u044e",//no i18n
	"crm.filter.label.a.month":"\u0432 \u043c\u0435\u0441\u044f\u0446",//no i18n
	"crm.cal.custom":"\u0414\u0440\u0443\u0433\u043e\u0435",//no i18n
	"crm.mb.field.common.empt":"\u0417\u043d\u0430\u0447\u0435\u043d\u0438\u0435 \u043d\u0435 \u043c\u043e\u0436\u0435\u0442 \u0431\u044b\u0442\u044c \u043f\u0443\u0441\u0442\u044b\u043c.",//no i18n
	"crm.chosen.error.loading.text":"\u041a \u0441\u043e\u0436\u0430\u043b\u0435\u043d\u0438\u044e, \u043d\u0435 \u0443\u0434\u0430\u043b\u043e\u0441\u044c \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c \u0440\u0435\u0437\u0443\u043b\u044c\u0442\u0430\u0442\u044b",//no i18n
	"crm.filter.label.firstbuy":"\u041f\u0435\u0440\u0432\u044b\u0439 \u0440\u0430\u0437",//no i18n
	"crm.filter.label.cwbab":"\u0417\u0430\u0432\u0438\u0441\u0438\u043c\u044b\u0439",//no i18n
	"crm.filter.label.fbt":"\u041f\u0430\u043a\u0435\u0442",//no i18n
	"crm.filter.label.rebuy":"\u041f\u043e\u0432\u0442\u043e\u0440\u044f\u0442\u044c",//no i18n
	"crm.filter.label.nextbuy":"\u041f\u043e\u0441\u043b\u0435\u0434\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c",//no i18n
	"crm.mxnlookup.select" : "\u041d\u0430\u0437\u043d\u0430\u0447\u0438\u0442\u044c: {0}",//No I18n
	"crm.lookup.chooserecord":"\u0412\u044b\u0431\u0435\u0440\u0438\u0442\u0435 {0}",//no i18n
	"crm.record.selected":"\u0412\u044b\u0431\u0440\u0430\u043d\u043e: {0}",//no i18n
	"crm.module.empty.message" : "{0} \u043d\u0435 \u043d\u0430\u0439\u0434\u0435\u043d\u044b",//No I18n
	"crm.mxnlookup.selected" : "\u041d\u0430\u0437\u043d\u0430\u0447\u0435\u043d. {0}",//No I18n
	"crm.security.error" : "\u0423 \u0432\u0430\u0441 \u043d\u0435\u0434\u043e\u0441\u0442\u0430\u0442\u043e\u0447\u043d\u043e \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u0438\u0439 \u043d\u0430 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u0435 \u044d\u0442\u043e\u0433\u043e \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044f. \u041e\u0431\u0440\u0430\u0442\u0438\u0442\u0435\u0441\u044c \u043a \u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440\u0443.", //No I18n
	"crm.label.creator.noPermission" : "\u0414\u043e\u0441\u0442\u0443\u043f \u0437\u0430\u043f\u0440\u0435\u0449\u0435\u043d", //No I18n
	"crm.segmentation.recency" : "\u0410\u043a\u0442\u0443\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c", //No I18n
	"crm.segmentation.frequency" : "\u041f\u0435\u0440\u0438\u043e\u0434\u0438\u0447\u043d\u043e\u0441\u0442\u044c", //No I18n
	"crm.segmentation.monetary" : "\u041c\u043e\u043d\u0435\u0442\u0438\u0437\u0430\u0446\u0438\u044f", //No I18n
	"crm.smartfilter.related.module.msg" : "\u041c\u043e\u0436\u043d\u043e \u0432\u044b\u0431\u0440\u0430\u0442\u044c \u043d\u0435 \u0431\u043e\u043b\u0435\u0435 \u0442\u0440\u0435\u0445 \u0441\u0432\u044f\u0437\u0430\u043d\u043d\u044b\u0445 \u043c\u043e\u0434\u0443\u043b\u0435\u0439.", //no i18n
	"crm.smartfilter.related.module.msg1" : "(\u041d\u0430\u043f\u0440\u0438\u043c\u0435\u0440: \u201c\u041f\u043e\u0447\u0442\u0430\u201d, \u201c\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u044f\u201d, \u201c\u041f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u044f\u201d)",//no i18n
	"crm.smartfilter.related.module.msg2" : "\u041d\u0435\u043e\u0431\u0445\u043e\u0434\u0438\u043c\u043e \u0443\u043a\u0430\u0437\u0430\u0442\u044c \u043f\u0440\u043e\u0434\u043e\u043b\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c", //no i18n
	"crm.label.timeZone": "\u0427\u0430\u0441\u043e\u0432\u043e\u0439 \u043f\u043e\u044f\u0441", //NO I18n
	"crm.label.insufficient.privileges": "\u041d\u0435\u0434\u043e\u0441\u0442\u0430\u0442\u043e\u0447\u043d\u043e \u043f\u043e\u043b\u043d\u043e\u043c\u043e\u0447\u0438\u0439, \u0447\u0442\u043e\u0431\u044b \u0432\u044b\u043f\u043e\u043b\u043d\u0438\u0442\u044c \u0434\u0430\u043d\u043d\u0443\u044e \u043e\u043f\u0435\u0440\u0430\u0446\u0438\u044e. \u0421\u0432\u044f\u0436\u0438\u0442\u0435\u0441\u044c \u0441\u043e \u0441\u0432\u043e\u0438\u043c \u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440\u043e\u043c.", //NO I18n
	"crm.filter.header.secton.system": "\u0424\u0438\u043b\u044c\u0442\u0440\u044b, \u043e\u043f\u0440\u0435\u0434\u0435\u043b\u044f\u0435\u043c\u044b\u0435 \u0441\u0438\u0441\u0442\u0435\u043c\u043e\u0439", //NO I18N
	"crm.filter.header.secton.fields": "\u0424\u0438\u043b\u044c\u0442\u0440\u043e\u0432\u0430\u0442\u044c \u043f\u043e \u043f\u043e\u043b\u044f\u043c", //NO I18N
	"crm.createfield.calcinfo.new" : "\u042d\u0442\u043e \u043f\u043e\u043b\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u044f\u0435\u0442 \u0444\u0443\u043d\u043a\u0446\u0438\u044e \u043a\u0430\u043b\u044c\u043a\u0443\u043b\u044f\u0442\u043e\u0440\u0430 \u0434\u043b\u044f \u043b\u044e\u0431\u043e\u0433\u043e \u0432\u0432\u0435\u0434\u0435\u043d\u043d\u043e\u0433\u043e \u0432\u0430\u043c\u0438 \u0432\u044b\u0440\u0430\u0436\u0435\u043d\u0438\u044f.</br> <b>\u041d\u0430\u043f\u0440., \u0435\u0441\u043b\u0438 \u0432\u0432\u0435\u0441\u0442\u0438 20+20</b>, \u0441\u0438\u0441\u0442\u0435\u043c\u0430 \u0430\u0432\u0442\u043e\u043c\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0438 \u0432\u044b\u0434\u0430\u0441\u0442 \u0437\u043d\u0430\u0447\u0435\u043d\u0438\u0435 <b>40</b>",//No i18n
	"crm.lable.read.only" : "\u041f\u043e\u043b\u0435 \u0442\u043e\u043b\u044c\u043a\u043e \u0434\u043b\u044f \u0447\u0442\u0435\u043d\u0438\u044f",//No i18n
	"crm.column.sort.asc" : "\u0421\u043e\u0440\u0442\u0438\u0440\u043e\u0432\u043a\u0430 \u043f\u043e \u0432\u043e\u0437\u0440\u0430\u0441\u0442\u0430\u043d\u0438\u044e",//No I18n
	"crm.column.sort.desc" : "\u0421\u043e\u0440\u0442\u0438\u0440\u043e\u0432\u043a\u0430 \u043f\u043e \u0443\u0431\u044b\u0432\u0430\u043d\u0438\u044e",//No i18n
	"crm.column.unsort" : "\u0411\u0435\u0437 \u0441\u043e\u0440\u0442\u0438\u0440\u043e\u0432\u043a\u0438",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "\u041f\u043e\u0434\u0435\u043b\u0438\u0442\u044c\u0441\u044f \u0441 \u043a\u043b\u0438\u0435\u043d\u0442\u043e\u043c", //NO I18N
	"crm.label.edited":"\u041e\u0442\u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u043e",//no i18n
	"crm.label.edited.on":"\u0418\u0437\u043c\u0435\u043d\u0435\u043d\u043e",//no i18n
	"crm.message.limit.exceed": "\u0414\u043b\u044f {1} \u0434\u043e\u043f\u0443\u0441\u043a\u0430\u0435\u0442\u0441\u044f \u0438\u0441\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u044c \u0442\u043e\u043b\u044c\u043a\u043e {0} \u0441\u0438\u043c\u0432\u043e\u043b\u043e\u0432.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "\u041f\u0440\u0435\u0434\u043e\u0441\u0442\u0430\u0432\u043b\u0435\u043d \u0434\u043e\u0441\u0442\u0443\u043f \u043a\u043b\u0438\u0435\u043d\u0442\u0443", //NO I18N
	"crm.button.ok" : "\u041e\u041a", //NO I18N
	"crm.role.already.selected" : "\u042d\u0442\u043e \u0440\u043e\u043b\u044c \u0443\u0436\u0435 \u0432\u044b\u0431\u0440\u0430\u043d\u0430", //no i18n
	"crm.user.deleted": "\u041f\u041e\u041b\u042c\u0417\u041e\u0412\u0410\u0422\u0415\u041b\u042c \u0423\u0414\u0410\u041b\u0415\u041d",  //NO I18N
	"crm.account.closed": "\u042d\u0422\u0410 \u0423\u0427\u0415\u0422\u041d\u0410\u042f \u0417\u0410\u041f\u0418\u0421\u042c \u0417\u0410\u041a\u0420\u042b\u0422\u0410",  //NO I18N
	"crm.start.chat": "\u041d\u0430\u0447\u0430\u0442\u044c \u0447\u0430\u0442",  //NO I18N
	"crm.start.call": "\u041d\u0430\u0447\u0430\u0442\u044c \u0432\u044b\u0437\u043e\u0432",  //NO I18N
	"crm.recipient.invalid.email" : "\u041e\u0431\u043d\u0430\u0440\u0443\u0436\u0435\u043d\u044b \u043d\u0435\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u0435 \u0430\u0434\u0440\u0435\u0441\u0430 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u043e\u0439 \u043f\u043e\u0447\u0442\u044b.", //NO I18N
	"crm.recipient.add.recipient" : "\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u0434\u043e\u043f\u043e\u043b\u043d\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0433\u043e \u043f\u043e\u043b\u0443\u0447\u0430\u0442\u0435\u043b\u044f", //NO I18N
	"crm.start.video.call": "\u041d\u0430\u0447\u0430\u0442\u044c \u0432\u0438\u0434\u0435\u043e\u0432\u044b\u0437\u043e\u0432",  //NO I18N //ignorei18n_start

	"crm.label.scoring.rules":"\u041f\u0440\u0430\u0432\u0438\u043b\u0430 \u043e\u0446\u0435\u043d\u043a\u0438",
	"Score":"\u041e\u0446\u0435\u043d\u043a\u0430",
	"Positive Score":"\u041f\u043e\u043b\u043e\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u0430\u044f \u043e\u0446\u0435\u043d\u043a\u0430",
	"Negative Score":"\u041e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u044c\u043d\u0430\u044f \u043e\u0446\u0435\u043d\u043a\u0430",
	"Touch Point Score":"\u041e\u0446\u0435\u043d\u043a\u0430 \u0442\u043e\u0447\u043a\u0438 \u0432\u0437\u0430\u0438\u043c\u043e\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044f",
	"Positive Touch Point Score":"\u041f\u043e\u043b\u043e\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u0430\u044f \u043e\u0446\u0435\u043d\u043a\u0430 \u0442\u043e\u0447\u043a\u0438 \u0432\u0437\u0430\u0438\u043c\u043e\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044f",
	"Negative Touch Point Score":"\u041e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u044c\u043d\u0430\u044f \u043e\u0446\u0435\u043d\u043a\u0430 \u0442\u043e\u0447\u043a\u0438 \u0432\u0437\u0430\u0438\u043c\u043e\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044f",
	"is\ OPEN":"\u2014 \u041e\u0422\u041a\u0420\u042b\u0422\u041e",//no i18n
	"is\ WON":"\u2014 \u0417\u0410\u041a\u041b\u042e\u0427\u0415\u041d\u041e",//no i18n
	"is\ LOST":"\u2014 \u0423\u041f\u0423\u0429\u0415\u041d\u041e",//no i18n
	"crm.potential.all.open":"\u0412\u0441\u0435 \u043e\u0442\u043a\u0440\u044b\u0442\u044b\u0435 \u044d\u0442\u0430\u043f\u044b",//no i18n
	"crm.potential.all.won":"\u0412\u0441\u0435 \u0437\u0430\u043a\u0440\u044b\u0442\u044b\u0435 \u0443\u0441\u043f\u0435\u0448\u043d\u044b\u0435 \u044d\u0442\u0430\u043f\u044b",//no i18n
	"crm.potential.all.lost":"\u0412\u0441\u0435 \u0437\u0430\u043a\u0440\u044b\u0442\u044b\u0435 \u043d\u0435\u0443\u0441\u043f\u0435\u0448\u043d\u044b\u0435 \u044d\u0442\u0430\u043f\u044b",//no i18n

	"crm.label.type.minutes":"\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0437\u043d\u0430\u0447\u0435\u043d\u0438\u0435 \u0432 \u043c\u0438\u043d\u0443\u0442\u0430\u0445",
	"crm.campaign.member.status" : "\u0421\u0442\u0430\u0442\u0443\u0441 \u0443\u0447\u0430\u0441\u0442\u043d\u0438\u043a\u0430",//no i18n
	"crm.dashboard.select.type" : "\u0412\u044b\u0431\u0435\u0440\u0438\u0442\u0435 {0}",//no i18n
	"crm.campaign.service.status":"\u0421\u0442\u0430\u0442\u0443\u0441 \u0441\u043b\u0443\u0436\u0431\u044b",//no i18n

	"crm.label.addColumn":"\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u0441\u0442\u043e\u043b\u0431\u0435\u0446",//no i18n
	"crm.button.clear.filter":"\u0417\u0430\u043a\u0440\u044b\u0442\u044c \u0444\u0438\u043b\u044c\u0442\u0440",//no i18n
	"crm.button.show.filter":"\u041f\u043e\u043a\u0430\u0437\u0430\u0442\u044c \u0444\u0438\u043b\u044c\u0442\u0440",//no i18n
	"crm.las.error.user.maxlimit":"\u041c\u043e\u0436\u043d\u043e \u0432\u044b\u0431\u0440\u0430\u0442\u044c \u043d\u0435 \u0431\u043e\u043b\u0435\u0435 20\u00a0\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0435\u0439.",//no i18n
	"crm.las.error.picklist.maxlimit":"\u041c\u043e\u0436\u043d\u043e \u0432\u044b\u0431\u0440\u0430\u0442\u044c \u043d\u0435 \u0431\u043e\u043b\u0435\u0435 20\u00a0\u0432\u0430\u0440\u0438\u0430\u043d\u0442\u043e\u0432.",//no i18n

	"crm.label.type.minutes":"\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0437\u043d\u0430\u0447\u0435\u043d\u0438\u0435 \u0432 \u043c\u0438\u043d\u0443\u0442\u0430\u0445", //NO I18N
	"crm.fileuploader.message.responseerror": "\u0421\u0431\u043e\u0439 \u043e\u0442\u043f\u0440\u0430\u0432\u043a\u0438", //NO I18N
	"crm.storage.create.error":"\u0421\u043e\u0437\u0434\u0430\u043d\u0438\u0435 \u043d\u043e\u0432\u044b\u0445 \u0437\u0430\u043f\u0438\u0441\u0435\u0439 \u043d\u0435\u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e, \u0442.\u00a0\u043a. \u0432\u044b \u0434\u043e\u0441\u0442\u0438\u0433\u043b\u0438 \u043b\u0438\u043c\u0438\u0442\u0430 \u043d\u0430 \u043e\u0431\u044a\u0435\u043c \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0430 \u0434\u0430\u043d\u043d\u044b\u0445.",//no i18n
	"crm.storage.create.error.client":"\u0421\u043e\u0437\u0434\u0430\u043d\u0438\u0435 \u043d\u043e\u0432\u044b\u0445 \u0437\u0430\u043f\u0438\u0441\u0435\u0439 \u043d\u0435\u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e, \u0442.\u00a0\u043a. \u0434\u043b\u044f \u0432\u0430\u0448\u0435\u0433\u043e \u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440\u0430 \u0434\u043e\u0441\u0442\u0438\u0433\u043d\u0443\u0442 \u043b\u0438\u043c\u0438\u0442 \u043d\u0430 \u043e\u0431\u044a\u0435\u043c \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0430. \u041e\u0431\u0440\u0430\u0442\u0438\u0442\u0435\u0441\u044c \u0432 {0}, \u0447\u0442\u043e\u0431\u044b \u0443\u0441\u0442\u0440\u0430\u043d\u0438\u0442\u044c \u044d\u0442\u0443 \u043f\u0440\u043e\u0431\u043b\u0435\u043c\u0443.",//no i18n
	"crm.storage.avail.info":"({0} \u043e\u0441\u0442\u0430\u043b\u043e\u0441\u044c \u0438\u0437 {1})",//no i18n
	"crm.storage.error.key.manage":"\u0423\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435\u043c \u0434\u0430\u043d\u043d\u044b\u0445",//no i18n
	"Records":"\u0417\u0430\u043f\u0438\u0441\u0438",//no i18n
	"crm.workflow.alert.additional.recipients" : "\u0414\u043e\u043f. \u043f\u043e\u043b\u0443\u0447\u0430\u0442\u0435\u043b\u0438", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "\u041c\u043e\u0436\u043d\u043e \u0443\u043a\u0430\u0437\u0430\u0442\u044c \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u0430\u0434\u0440\u0435\u0441\u043e\u0432 \u044d\u043b. \u043f\u043e\u0447\u0442\u044b \u0447\u0435\u0440\u0435\u0437 \u0437\u0430\u043f\u044f\u0442\u0443\u044e.", //NO I18N
	"crm.related.contact.account" : "{0}, \u0441\u0432\u044f\u0437\u0430\u043d. \u0441 {1}",//No I18n
	"crm.allcontact.show" : "\u0412\u0441\u0435 {0}",//No I18n
	"crm.button.mass.show" : "Show",//No I18n
	"crm.msg.custom.view.not.replied" : "\u0421\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u044f \u0431\u0435\u0437 \u043e\u0442\u0432\u0435\u0442\u0430", //NO I18N
	"crm.msg.custom.view.replied" : "\u0421\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u044f \u0441 \u043e\u0442\u0432\u0435\u0442\u043e\u043c",//NO I18N
	"crm.workflow.select.recipients" : "\u041f\u043e\u043b\u0443\u0447\u0430\u0442\u0435\u043b\u0438", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"\u0412\u044b\u0431\u0435\u0440\u0438\u0442\u0435 \u0445\u043e\u0442\u044f \u0431\u044b \u043e\u0434\u0438\u043d \u043f\u0440\u043e\u0444\u0438\u043b\u044c.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "\u041a \u0441\u043e\u0436\u0430\u043b\u0435\u043d\u0438\u044e, \u043d\u0435\u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e \u0443\u0434\u0430\u043b\u0438\u0442\u044c \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043f\u043e \u0443\u043c\u043e\u043b\u0447\u0430\u043d\u0438\u044e.",//NO I18N
	"crm.inv.label.add.emails" : "\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u0430\u0434\u0440\u0435\u0441\u0430 \u044d\u043b. \u043f\u043e\u0447\u0442\u044b" ,//NO I18N
	"crm.prediction.analytics.filter.year":"\u041f\u0440\u043e\u0448\u043b\u044b\u0439 \u0433\u043e\u0434",//no i18n
	"Previous\ FY":"\u041f\u0440\u0435\u0434\u044b\u0434\u0443\u0449\u0438\u0439 \u0444\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0433\u043e\u0434",//no i18n
	"Current\ FY":"\u0422\u0435\u043a\u0443\u0449\u0438\u0439 \u0444\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0433\u043e\u0434",//no i18n
	"Next\ FY":"\u0421\u043b\u0435\u0434\u0443\u044e\u0449\u0438\u0439 \u0444\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0433\u043e\u0434",//no i18n
	"Current\ FQ":"\u0422\u0435\u043a\u0443\u0449\u0438\u0439 \u0444\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u043a\u0432\u0430\u0440\u0442\u0430\u043b",//no i18n
	"Next\ FQ":"\u0421\u043b\u0435\u0434\u0443\u044e\u0449\u0438\u0439 \u0444\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u043a\u0432\u0430\u0440\u0442\u0430\u043b",//no i18n
	"Previous\ FQ":"\u041f\u0440\u0435\u0434\u044b\u0434\u0443\u0449\u0438\u0439 \u0444\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u043a\u0432\u0430\u0440\u0442\u0430\u043b",//no i18n
	"crm.inv.label.add.emails" : "\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u0430\u0434\u0440\u0435\u0441\u0430 \u044d\u043b. \u043f\u043e\u0447\u0442\u044b", //NO I18N
	"crm.picklist.sample.text":"\u041f\u0440\u0438\u043c\u0435\u0440 \u0442\u0435\u043a\u0441\u0442\u0430",//no i18n
	"crm.more.colors":"\u0414\u043e\u043f\u043e\u043b\u043d\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u0435 \u0446\u0432\u0435\u0442\u0430",//no i18n
	"crm.button.back.alone":"\u041d\u0430\u0437\u0430\u0434",//no i18n
	"crm.field.label.email":"\u042d\u043b.\u00a0\u043f\u043e\u0447\u0442\u0430",//no i18n
	"crm.zia.nba.feature.label":"\u0421\u043b\u0435\u0434\u0443\u044e\u0449\u0435\u0435 \u043d\u0430\u0438\u043b\u0443\u0447\u0448\u0435\u0435 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435",//no i18n
	"Meeting":"\u0421\u043e\u0431\u0440\u0430\u043d\u0438\u0435",//no i18n
	"Tomorrow":"\u0417\u0430\u0432\u0442\u0440\u0430",//no i18n
	"crm.gdpr.notavailable.field":"\u041d\u0435\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u043e",//no i18n
	"crm.setup.system.ziasimilarity":"\u0420\u0435\u043a\u043e\u043c\u0435\u043d\u0434\u0430\u0446\u0438\u044f \u043f\u043e \u0441\u0445\u043e\u0434\u0441\u0442\u0432\u0443",//no i18n
	"crm.gdpr.notavailable.field":"\u041d\u0435\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u043e",//no i18n
	"crm.label.simply.in":"\u0432",//no i18n
	"crm.filter.label.all.products":"\u0412\u0441\u0435 {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due" ,//NO I18N
	"abm.segment": "\u0421\u0435\u0433\u043c\u0435\u043d\u0442", // NO I18N
	"abm.segment.names": "\u0418\u043c\u0435\u043d\u0430 \u0441\u0435\u0433\u043c\u0435\u043d\u0442\u043e\u0432", // NO I18N
	"abm.abm.segment.name": "\u0418\u043c\u044f \u0441\u0435\u0433\u043c\u0435\u043d\u0442\u0430 ABM", // NO I18N
	"abm.segmentation.techniques": "\u041c\u0435\u0442\u043e\u0434\u044b \u0441\u0435\u0433\u043c\u0435\u043d\u0442\u0430\u0446\u0438\u0438 ABM", // NO I18N
	"abm.filters": "\u0424\u0438\u043b\u044c\u0442\u0440\u044b ABM", // NO I18N
	"abm.filters.info": "\u042d\u0442\u0438 \u0444\u0438\u043b\u044c\u0442\u0440\u044b \u043f\u043e\u0437\u0432\u043e\u043b\u044f\u044e\u0442 \u043f\u0440\u043e\u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0441\u0432\u0435\u0434\u0435\u043d\u0438\u044f \u043d\u0430 \u043e\u0441\u043d\u043e\u0432\u0435 {0}, \u0441\u0432\u044f\u0437\u0430\u043d\u043d\u044b\u0445 \u0441 {1}.", // NO I18N
	"abm.rfm.label": "\u041c\u0435\u0442\u043a\u0430 RFM", // NO I18N
	"abm.firmographics.label": "\u041c\u0435\u0442\u043a\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0447\u0435\u0441\u043a\u043e\u0439 \u0434\u0435\u043c\u043e\u0433\u0440\u0430\u0444\u0438\u0447\u0435\u0441\u043a\u043e\u0439 \u0438\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u0438" ,// NO I18N
	"abm.recommendation.label": "\u041c\u0435\u0442\u043a\u0430 \u0440\u0435\u043a\u043e\u043c\u0435\u043d\u0434\u0430\u0446\u0438\u0438", // NO I18N
	"abm.voc.label": "\u041c\u0435\u0442\u043a\u0430 VOC", // NO I18N
	"abm.engagement.label": "\u041c\u0435\u0442\u043a\u0430 \u0432\u043e\u0432\u043b\u0435\u0447\u0435\u043d\u043d\u043e\u0441\u0442\u0438", // NO I18N
	"abm.overall.score": "\u041e\u0431\u0449\u0430\u044f \u043e\u0446\u0435\u043d\u043a\u0430", // NO I18N
	"abm.mood.score": "\u041e\u0446\u0435\u043d\u043a\u0430 \u043d\u0430\u0441\u0442\u0440\u043e\u0435\u043d\u0438\u044f", // NO I18N
	"abm.value.score": "\u041e\u0446\u0435\u043d\u043a\u0430 \u043f\u043e\u043b\u0435\u0437\u043d\u043e\u0441\u0442\u0438", // NO I18N
	"abm.engagement.score": "\u041e\u0446\u0435\u043d\u043a\u0430 \u0432\u043e\u0432\u043b\u0435\u0447\u0435\u043d\u043d\u043e\u0441\u0442\u0438", // NO I18N
	"abm.rfm.score": "\u041e\u0446\u0435\u043d\u043a\u0430 RFM", // NO I18N
	"crm.customview.pin.column":"\u0417\u0430\u043a\u0440\u0435\u043f\u0438\u0442\u044c \u0441\u0442\u043e\u043b\u0431\u0435\u0446",//no i18n
"crm.customview.unpin.column":"\u041e\u0442\u043a\u0440\u0435\u043f\u0438\u0442\u044c \u0441\u0442\u043e\u043b\u0431\u0435\u0446",//no i18n
"crm.column.list.max.group.limit" : "", //no i18n
	"crux.custom.field.greater.than.equalto":"{0} должно быть меньше или равно {1}.",
	"crux.users.selected.plural" : "Выбрано пользователей: {0}.",
	"crux.user.selected.singular" :"Выбрано пользователей: {0}.",
	"crux.criteria.empty.secondaryfield.module" : "В модуле {1} не обнаружено подходящих полей {0}",
	"crux.criteria.empty.secondaryfield" : "Другое поле {0} для сравнения не найдено. Пожалуйста, введите значение для сравнения.",
	"crux.logged.in.role.definition" : "Роль пользователя, инициирующего действия с записями",
	"zia.similarity.smartfilter.score":"\u041e\u0446\u0435\u043d\u043a\u0430 \u0441\u0445\u043e\u0436\u0435\u0441\u0442\u0438",//no i18n
	"zia.similarity.smartfilter.records":"\u0421\u0445\u043e\u0436\u0438 {0} \u0438\u0437",//no i18n
	"zia.similarity.smartfilter.records.search":"\u041f\u043e\u043a\u0430\u0437\u0430\u0442\u044c \u0441\u0445\u043e\u0436\u0438\u0435 {0} \u0438\u0437",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"\u041c\u043e\u0436\u043d\u043e \u0432\u044b\u0431\u0440\u0430\u0442\u044c \u043d\u0435 \u0431\u043e\u043b\u0435\u0435 {0} {1}",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} должно быть меньше или равно {1}.",
	"crux.users.selected.plural" : "Выбрано пользователей: {0}.",
	"crux.user.selected.singular" :"Выбрано пользователей: {0}.",
	"crux.criteria.empty.secondaryfield.module" : "В модуле {1} не обнаружено подходящих полей {0}",
	"crux.criteria.empty.secondaryfield" : "Другое поле {0} для сравнения не найдено. Пожалуйста, введите значение для сравнения.",
	"crux.logged.in.role.definition" : "Роль пользователя, инициирующего действия с записями",
	"crux.max.limit.unselect" : "Можно отменить выбор не более {0} {1}.", //NO I18N
	"crux.existing.tag" : "\u201c{0}\u201d уже выбран",// NO I18N
	"crm.label.true" : "\u0418\u0441\u0442\u0438\u043d\u0430",//NO I18N
	"crm.label.false" : "\u041b\u043e\u0436\u044c",//NO I18N
	"crm.record.locked" : "\u0417\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d\u043e",//NO I18N
	"crm.filter.number.range":"\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043e\u0442 {0}\u2013{1}",//no i18n
	"crm.smartfilter.picklist.options.msg":"\u041d\u0435\u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e \u0432\u044b\u0431\u0440\u0430\u0442\u044c \u044d\u0442\u043e\u0442 \u0432\u0430\u0440\u0438\u0430\u043d\u0442 \u0431\u043e\u043b\u0435\u0435 \u0447\u0435\u043c \u0434\u043b\u044f 10\u00a0\u0432\u044b\u0431\u0440\u0430\u043d\u043d\u044b\u0445 \u0437\u043d\u0430\u0447\u0435\u043d\u0438\u0439.",//no i18n
	"crm.chosen.maximum.campaigns.selected":"\u041c\u043e\u0436\u043d\u043e \u0432\u044b\u0431\u0440\u0430\u0442\u044c \u043d\u0435 \u0431\u043e\u043b\u0435\u0435 5\u00a0{0}",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"\u041c\u043e\u0436\u043d\u043e \u0432\u044b\u0431\u0440\u0430\u0442\u044c \u043d\u0435 \u0431\u043e\u043b\u0435\u0435 {0} {1}",//no i18n
	"crm.best.time.column.label":"\u041d\u0430\u0438\u043b\u0443\u0447\u0448\u0435\u0435 \u0432\u0440\u0435\u043c\u044f \u043e\u0431\u0440\u0430\u0449\u0435\u043d\u0438\u044f",//no i18n
	"crm.competitoralert.mentioned.in.email":"\u0423\u043f\u043e\u043c\u044f\u043d\u0443\u0442\u043e \u0432 <b>\u043f\u0438\u0441\u044c\u043c\u0435</b>",//no i18n
	"crm.competitoralert.with.sentiment":"\u0421 \u0442\u043e\u043d\u043e\u043c",//no i18n
	"crm.competitoralert.name.error":"\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u043e\u0435 \u0438\u043c\u044f \u043a\u043e\u043d\u043a\u0443\u0440\u0435\u043d\u0442\u0430.",//no i18n
	"crm.competitoralert.date.error":"\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0443\u044e \u0434\u0430\u0442\u0443 \u0443\u043f\u043e\u043c\u0438\u043d\u0430\u043d\u0438\u044f \u043a\u043e\u043d\u043a\u0443\u0440\u0435\u043d\u0442\u0430 \u0432 \u043f\u0438\u0441\u044c\u043c\u0435.",//no i18n
	"crm.competitoralert.sentiment.error":"\u0412\u044b\u0431\u0435\u0440\u0438\u0442\u0435 \u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u044b\u0439 \u0432\u0430\u0440\u0438\u0430\u043d\u0442 \u0434\u043b\u044f \u0442\u043e\u043d\u0430 \u043a\u043e\u043d\u043a\u0443\u0440\u0435\u043d\u0442\u0430.",//no i18n
	"crm.competitor.name":"\u041a\u043e\u043d\u043a\u0443\u0440\u0435\u043d\u0442",
"crm.lookup.advance.error.msg" : "\u041e\u0448\u0438\u0431\u043a\u0430 \u043f\u0440\u0438 \u043f\u0440\u0438\u043c\u0435\u043d\u0435\u043d\u0438\u0438 \u0444\u0438\u043b\u044c\u0442\u0440\u0430. \u041e\u0431\u043d\u043e\u0432\u0438\u0442\u0435 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0443 \u0438 \u043f\u043e\u0432\u0442\u043e\u0440\u0438\u0442\u0435 \u043f\u043e\u043f\u044b\u0442\u043a\u0443.",
	"Contact\ Name" : "{0} - \u0438\u043c\u044f",
	"Lead\ Name" : "{0} - \u0438\u043c\u044f",
	"-None-" : "\u041d\u0435\u0442",
	"January" : "\u042f\u043d\u0432\u0430\u0440\u044c",
	"February":"\u0424\u0435\u0432\u0440\u0430\u043b\u044c",
	"March":"\u041c\u0430\u0440\u0442",
	"April":"\u0410\u043f\u0440\u0435\u043b\u044c",
	"May":"\u041c\u0430\u0439",
	"June":"\u0418\u044e\u043d\u044c",
	"July":"\u0418\u044e\u043b\u044c",
	"August":"\u0410\u0432\u0433\u0443\u0441\u0442",
	"September":"\u0421\u0435\u043d\u0442\u044f\u0431\u0440\u044c",
	"October":"\u041e\u043a\u0442\u044f\u0431\u0440\u044c",
	"November":"\u041d\u043e\u044f\u0431\u0440\u044c",
	"December":"\u0414\u0435\u043a\u0430\u0431\u0440\u044c",
	"crm.label.add.tags":"\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u0442\u0435\u0433\u0438",//no i18n
	"crm.confirm.deassociate":"\u041e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u0435",//no i18n
	// Image Upload Open
	"crm.label.browse.files":"\u041e\u0431\u0437\u043e\u0440 \u0444\u0430\u0439\u043b\u043e\u0432",//no i18n
	"crm.label.lowercase.or":"\u0438\u043b\u0438",//no i18n
	"crm.image.header":"\u0418\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435",//no i18n
	"crm.image.n.n":"\u0421\u043e\u043e\u0442\u043d\u043e\u0448\u0435\u043d\u0438\u0435 N:N",//no i18n
	"crm.image.2.2":"\u0421\u043e\u043e\u0442\u043d\u043e\u0448\u0435\u043d\u0438\u0435 2:2",//no i18n
	"crm.image.4.3":"\u0421\u043e\u043e\u0442\u043d\u043e\u0448\u0435\u043d\u0438\u0435 4:3",//no i18n
	"crm.image.16.9":"\u0421\u043e\u043e\u0442\u043d\u043e\u0448\u0435\u043d\u0438\u0435 16:9",//no i18n
	"crm.image.height.width.swap":"\u0417\u0430\u043c\u0435\u043d\u0438\u0442\u044c \u0432\u044b\u0441\u043e\u0442\u0443 \u0438 \u0448\u0438\u0440\u0438\u043d\u0443",//no i18n
	"crm.image.rotate.image":"\u041f\u043e\u0432\u043e\u0440\u043e\u0442",//no i18n
	"crm.label.lowercase.of":"\u0438\u0437",//no i18n
	"crm.image.empty.name.placeholder" : "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0438\u043c\u044f",
	"crm.image.crop.and.rotate" : "\u041e\u0431\u0440\u0435\u0437\u0430\u0442\u044c \u0438 \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c",
	"crm.image.description" : "\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u043e\u043f\u0438\u0441\u0430\u043d\u0438\u0435...",
	"crm.image.actual.size" : "\u0424\u0430\u043a\u0442\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u0440\u0430\u0437\u043c\u0435\u0440",
	"crm.image.reset" : "\u0421\u0431\u0440\u043e\u0441",
	"crm.image.width.px" : "px",
	"crm.view.attachment.download":"\u0417\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c",//no i18n
	"crm.label.edit":"\u0418\u0437\u043c\u0435\u043d\u0438\u0442\u044c",//no i18n
	"crm.label.close":"\u0417\u0430\u043a\u0440\u044b\u0442\u044c",//no i18n
	"crm.label.any":"\u041b\u044e\u0431\u044b\u0435",
	"crm.image.description":"\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u043e\u043f\u0438\u0441\u0430\u043d\u0438\u0435...",//no i18n
	"crm.image.zoom.in":"\u0423\u0432\u0435\u043b\u0438\u0447\u0438\u0442\u044c \u043c\u0430\u0441\u0448\u0442\u0430\u0431",//no i18n
	"crm.image.zoom.out":"\u0423\u043c\u0435\u043d\u044c\u0448\u0438\u0442\u044c \u043c\u0430\u0441\u0448\u0442\u0430\u0431",//no i18n
	"crm.label.lowercase.of":"\u0438\u0437",//no i18n
	"crm.image.desc.maxsize":"\u0414\u043b\u0438\u043d\u0430 \u043e\u043f\u0438\u0441\u0430\u043d\u0438\u044f \u043d\u0435 \u0434\u043e\u043b\u0436\u043d\u0430 \u043f\u0440\u0435\u0432\u044b\u0448\u0430\u0442\u044c 255\u00a0\u0441\u0438\u043c\u0432\u043e\u043b\u043e\u0432.",//no i18n
	"crm.image.name.maxsize":"\u0414\u043b\u0438\u043d\u0430 \u0438\u043c\u0435\u043d\u0438 \u043d\u0435 \u0434\u043e\u043b\u0436\u043d\u0430 \u043f\u0440\u0435\u0432\u044b\u0448\u0430\u0442\u044c 100\u00a0\u0441\u0438\u043c\u0432\u043e\u043b\u043e\u0432.",//no i18n
	"crm.fileuploader.message.totalfilesizeexceeded":"\u041e\u0431\u0449\u0438\u0439 \u0440\u0430\u0437\u043c\u0435\u0440 \u0444\u0430\u0439\u043b\u043e\u0432 \u043f\u0440\u0435\u0432\u044b\u0448\u0430\u0435\u0442 \u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u043e\u0435 \u043e\u0433\u0440\u0430\u043d\u0438\u0447\u0435\u043d\u0438\u0435 ( \u043d\u0435 \u0431\u043e\u043b\u0435\u0435 {0}).",//no i18n
	"crm.fileuploader.message.maxfilesexceeded":"\u041c\u043e\u0436\u043d\u043e \u043f\u0435\u0440\u0435\u0434\u0430\u0442\u044c \u0442\u043e\u043b\u044c\u043a\u043e \u043e\u043f\u0440\u0435\u0434\u0435\u043b\u0435\u043d\u043d\u043e\u0435 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0444\u0430\u0439\u043b\u043e\u0432 (\u043d\u0435 \u0431\u043e\u043b\u0435\u0435 {0}).",//no i18n
	"crm.fileuploader.message.maxfilesexceeded1":"\u041c\u043e\u0436\u043d\u043e \u0432\u043b\u043e\u0436\u0438\u0442\u044c \u0442\u043e\u043b\u044c\u043a\u043e \u043e\u043f\u0440\u0435\u0434\u0435\u043b\u0435\u043d\u043d\u043e\u0435 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0444\u0430\u0439\u043b\u043e\u0432 (\u043d\u0435 \u0431\u043e\u043b\u0435\u0435 {0}).",//no i18n
	"crm.ImageuploadField.size.limit":"\u041e\u0431\u0449\u0438\u0439 \u0440\u0430\u0437\u043c\u0435\u0440 \u0444\u0430\u0439\u043b\u043e\u0432 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0439 \u043f\u0440\u0435\u0432\u044b\u0448\u0430\u0435\u0442 \u043e\u0433\u0440\u0430\u043d\u0438\u0447\u0435\u043d\u0438\u0435 (\u043d\u0435 \u0431\u043e\u043b\u0435\u0435 {0}\u00a0\u041c\u0411).",//no i18n
	"crm.attachment.imageUploadField.Minlen.check":"\u041c\u043e\u0436\u043d\u043e \u0432\u044b\u0431\u0440\u0430\u0442\u044c \u0442\u043e\u043b\u044c\u043a\u043e 1\u00a0\u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435",//no i18n
	"crm.image.crop.error":"\u041d\u0435 \u0443\u0434\u0430\u043b\u043e\u0441\u044c \u043e\u0431\u0440\u0435\u0437\u0430\u0442\u044c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435. \u041f\u043e\u0432\u0442\u043e\u0440\u0438\u0442\u0435 \u043f\u043e\u043f\u044b\u0442\u043a\u0443!",//no i18n
	"crm.attach.here":"\u0437\u0434\u0435\u0441\u044c.",//no i18n
	"crm.image.unsupported.corrupted.single":"\u0424\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f, \u043a\u043e\u0442\u043e\u0440\u0443\u044e \u0432\u044b \u043f\u044b\u0442\u0430\u0435\u0442\u0435\u0441\u044c \u043e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c, \u043f\u043e\u0432\u0440\u0435\u0436\u0434\u0435\u043d\u0430.",//no i18n
	"Browse":"\u041e\u0431\u0437\u043e\u0440",//no i18n
	"crm.imageupload.failure":"\u041d\u0435 \u0443\u0434\u0430\u043b\u043e\u0441\u044c \u043e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u043d\u0435\u043a\u043e\u0442\u043e\u0440\u044b\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u044f. \u041f\u043e\u0432\u0442\u043e\u0440\u0438\u0442\u0435 \u043f\u043e\u043f\u044b\u0442\u043a\u0443 \u043f\u043e\u0437\u0436\u0435.",//no i18n
	"zc.editor.width":"\u0428\u0438\u0440\u0438\u043d\u0430",//no i18n
	"ze.editor.height":"\u0412\u044b\u0441\u043e\u0442\u0430",//no i18n
	"crm.label.delete":"\u0423\u0434\u0430\u043b\u0438\u0442\u044c",//no i18n
	"crm.image.error.gif":"\u041d\u0435\u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e \u043e\u0431\u0440\u0435\u0437\u0430\u0442\u044c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0432 \u0444\u043e\u0440\u043c\u0430\u0442\u0435 GIF.",//no i18n
	"crm.fileuploader.message.morefiles":"\u0415\u0449\u0435 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u0444\u0430\u0439\u043b\u043e\u0432 ({0})",//no i18n
	"crm.fileuploader.message.invalidfileType1":"\u0424\u0430\u0439\u043b \u201c{0}\u201d \u043d\u0435 \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0435\u0442\u0441\u044f.",//no i18n
	"crm.fileuploader.message.invalidfileType2":"\u0424\u0430\u0439\u043b\u044b \u201c{0}\u201d \u0438 \u201c{1}\u201d \u043d\u0435 \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u044e\u0442\u0441\u044f.",//no i18n
	"crm.attach.upload.sizelimit":"\u041e\u0433\u0440\u0430\u043d\u0438\u0447\u0435\u043d\u0438\u0435 \u043d\u0430 \u043e\u0431\u0449\u0438\u0439 \u0440\u0430\u0437\u043c\u0435\u0440",//no i18n
	
	
	
	
	"crm.fileuploader.message.maxfilesexceeded" : "\u041c\u043e\u0436\u043d\u043e \u043f\u0435\u0440\u0435\u0434\u0430\u0442\u044c \u0442\u043e\u043b\u044c\u043a\u043e \u043e\u043f\u0440\u0435\u0434\u0435\u043b\u0435\u043d\u043d\u043e\u0435 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0444\u0430\u0439\u043b\u043e\u0432 (\u043d\u0435 \u0431\u043e\u043b\u0435\u0435 {0}).",
	"crm.file.upload.maxlength.exceeds" : "\u0412 {0} \u043c\u043e\u0436\u043d\u043e \u043e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u0442\u043e\u043b\u044c\u043a\u043e \u043e\u0434\u0438\u043d \u0444\u0430\u0439\u043b."



}
